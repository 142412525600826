import { EModals } from "src/contexts/ModalProvider/interface";
import { ModalProvider } from "src/contexts/ModalProvider/ModalProvider";
import Translate from "../Translate/Translate";
import { ModalActionRowProps } from "./interface";
import { ActionWrapper, FilterImg, SearchImg } from "./styles";

export default function ModalActionRow({ loading }: ModalActionRowProps) {
  const { showModal } = ModalProvider();

  function openFilterModal() {
    showModal(EModals.FILTER_MODAL, {});
  }

  function openSearchModal() {
    showModal(EModals.SEARCH_MODAL, {});
  }

  return (
    <ActionWrapper loading={loading ? loading.toString() : undefined}>
      <SearchImg onClick={openSearchModal} />
      <span>
        <Translate value="General.Home.Title" />
      </span>
      <FilterImg onClick={openFilterModal} />
    </ActionWrapper>
  );
}
