import Translate from "../Translate/Translate";
import { FooterStyled } from "./styles";
import { FooterProps } from "./interface";

export default function Footer({ loading }: FooterProps) {
  return (
    <FooterStyled loading={loading ? loading.toString() : undefined}>
      <p>
        <Translate value="General.Footer.BocachicaLaunchpad" />
      </p>
      <p>
        <Translate value="General.Footer.Copyright" />
      </p>
    </FooterStyled>
  );
}
