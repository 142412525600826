import { formatUnits } from "ethers/lib/utils";
import { SaleDataById } from "src/shared/common/interfaces/Sale";

export function getValuesSaleData(currentSale: SaleDataById) {
  const deposited = formatUnits(
    currentSale.currentDeposit,
    currentSale.depositToken.decimals
  );

  const target = formatUnits(
    currentSale.targetDeposit,
    currentSale.depositToken.decimals
  );

  const minDep = formatUnits(
    currentSale.minDeposit,
    currentSale.depositToken.decimals
  );

  const maxDep = formatUnits(
    currentSale.maxDeposit,
    currentSale.depositToken.decimals
  );

  return { deposited, target, minDep, maxDep };
}
