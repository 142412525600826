import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding: 1rem;
  margin-top: 1.5rem;
  border-radius: 1rem;
  max-height: 14.125rem;
  background: ${({ theme }) => theme.colors.globalWhite};
  box-shadow: 0 0.25rem 0.5rem -0.25rem ${({ theme }) => theme.shadowColor.shadowWrapper};

  &:first-child {
    margin-top: 0;
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: 3rem;
  border-radius: 0.25rem;
  background-color: ${({ theme }) => theme.colors.opacityGrey};
`;

export const SideWrapper = styled.div`
  display: flex;
  margin-top: 1.375rem;
  align-items: flex-end;
  justify-content: space-between;

  div {
    &:last-child {
      display: flex;
      align-items: flex-end;
      flex-direction: column;
    }
  }
`;

export const Title = styled.div`
  width: 8.063rem;
  height: 0.75rem;
  border-radius: 0.25rem;
  background-color: ${({ theme }) => theme.colors.opacityGrey};
`;

export const IconRow = styled.div`
  display: flex;
  margin-top: 0.75rem;
  align-items: center;
`;

export const Icon = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  margin-right: 0.5rem;
  background-color: ${({ theme }) => theme.colors.opacityGrey};
`;

export const Stage = styled.div`
  height: 0.5rem;
  width: 3.938rem;
  border-radius: 0.25rem;
  background-color: ${({ theme }) => theme.colors.opacityGrey};
`;

export const PriceName = styled.div`
  height: 0.5rem;
  width: 2.688rem;
  margin-top: 1.25rem;
  border-radius: 0.25rem;
  background-color: ${({ theme }) => theme.colors.opacityGrey};
`;

export const PriceValue = styled.div`
  height: 0.75rem;
  width: 4.875rem;
  margin-top: 0.75rem;
  border-radius: 0.25rem;
  background-color: ${({ theme }) => theme.colors.opacityGrey};
`;
