import { v4 as uuid } from "uuid";
import { useNavigate } from "react-router-dom";
import {
  Image,
  ImageContainer,
  Label,
  Row,
  Title,
  WrapperDescription,
} from "../Modals/SearchModal/styles";
import Translate from "../Translate/Translate";
import StatusLabel from "../StatusLabel/StatusLabel";
import { SaleDataById } from "src/shared/common/interfaces/Sale";

export default function SearchResultRow({ close, sales }: any) {
  const navigate = useNavigate();

  return sales.map((sale: SaleDataById) => (
    <Row
      key={`${uuid()}-${sale.saleId}`}
      onClick={() => {
        close();
        navigate(`/${sale.saleId}`);
      }}
    >
      <ImageContainer>
        <Image src={sale.depositToken.icon} alt="logo" />
      </ImageContainer>
      <WrapperDescription>
        <Title>{sale.project.name}</Title>
        <Label>
          <Translate
            value="Sale.Stage"
            interpolation={{ symbol: sale.depositToken.symbol }}
          />
        </Label>
      </WrapperDescription>
      <StatusLabel saleStatus={sale.status} />
    </Row>
  ));
}
