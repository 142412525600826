import styled from "styled-components";

export const ErrorWarningStyled = styled.p`
  font-weight: 400;
  margin-left: 1rem;
  margin-top: 0.5rem;
  font-size: 0.75rem;
  font-family: "Inter", sans-serif;
  color: ${({ theme }) => theme.colors.negative};
`;
