import styled from "styled-components";

import { ReactComponent as SearchIcon } from "src/shared/assets/icons/search.svg";
import { ReactComponent as CloseIcon } from "src/shared/assets/icons/close-modal.svg";
import { ReactComponent as ClearSearchIcon } from "src/shared/assets/icons/clear-search.svg";

export const Header = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  margin-bottom: 2rem;
  justify-content: center;

  & > p {
    margin: 0;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
`;

export const StyledInput = styled.div`
  width: 100%;
  display: flex;
  min-height: 3rem;
  position: relative;
  align-items: center;
  margin-right: 2.5rem;
  border-radius: 0.5rem;
  border: 2px solid ${({ theme }) => theme.colors.borderBlue};

  :focus-within {
    border: 2px solid ${({ theme }) => theme.colors.globalGreen};
  }
`;

export const Input = styled.input`
  width: 100%;
  border: none;
  outline: none;
  margin: 0 3rem;
  font-size: 1rem;
  font-weight: 500;
  background: none;
  font-style: normal;
  line-height: 1.188rem;
  color: ${({ theme }) => theme.colors.globalBlack};
`;

export const ClearSearch = styled(ClearSearchIcon)`
  right: 14px;
  width: 22px;
  height: 22px;
  position: absolute;
`;

export const Search = styled(SearchIcon)`
  left: 14px;
  width: 24px;
  height: 24px;
  fill: black;
  position: absolute;
`;

export const Close = styled(CloseIcon)`
  right: 0;
  fill: black;
  width: 14px;
  height: 14px;
  cursor: pointer;
  position: absolute;
`;

export const Body = styled.div`
  flex: 1;
  display: flex;
  overflow: scroll;
  min-height: 25rem;
  min-width: 33.75rem;
  flex-direction: column;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    min-width: 100%;
    height: 100vh;
  `}
`;

export const Row = styled.div`
  display: flex;
  cursor: pointer;
  min-height: 3rem;
  margin: 0.75rem 0;
  align-items: center;
  justify-content: space-between;
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  max-width: 3rem;
  max-height: 3rem;
  justify-content: center;
`;

export const Image = styled.img`
  height: 100%;
  max-width: 3rem;
  max-height: 3rem;
`;

export const WrapperDescription = styled.div`
  flex: 1;
  display: flex;
  min-height: 3rem;
  margin-left: 0.75rem;
  flex-direction: column;
`;

export const Title = styled.p`
  margin: 0;
  font-size: 1rem;
  font-weight: 600;
  font-style: normal;
  line-height: 1.375rem;
  font-family: "Inter", sans-serif;
  color: ${({ theme }) => theme.colors.globalBlack};
`;

export const Label = styled.p`
  margin: 0;
  font-weight: 400;
  font-style: normal;
  font-size: 0.75rem;
  line-height: 1.063rem;
  font-family: "Inter", sans-serif;
  color: ${({ theme }) => theme.colors.primaryGrey};
`;

export const NoResultsWrapper = styled.div`
  display: flex;
  max-width: 20rem;
  align-self: center;
  align-items: center;
  flex-direction: column;
`;

export const NoResultsTitle = styled.p`
  font-weight: 600;
  font-style: normal;
  font-size: 1.25rem;
  text-align: center;
  line-height: 1.75rem;
  margin: 1.5rem 0 0.75rem;
  font-family: "Inter", sans-serif;
  color: ${({ theme }) => theme.colors.globalBlack};
`;

export const NoResultsLabel = styled.p`
  margin: 0;
  font-weight: 400;
  text-align: center;
  font-style: normal;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-family: "Inter", sans-serif;
  color: ${({ theme }) => theme.colors.primaryGrey};
`;

export const NoResultsIcon = styled(SearchIcon)`
  width: 27px;
  height: 27px;
  fill: black;
`;
