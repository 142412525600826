import { useEffect, useState } from "react";
import { differenceInMilliseconds } from "date-fns";
import { formatCountdown } from "src/shared/common/helpers/formatCountdown";

const useCountdown = (deadline: number) => {
  const [countdownInSeconds, setCountdownInSeconds] = useState(0);

  useEffect(() => {
    const timerId = setTimeout(() => {
      setCountdownInSeconds(
        differenceInMilliseconds(deadline, Math.floor(Date.now() / 1000))
      );
    }, 1000);
    return () => clearTimeout(timerId);
  });
  return formatCountdown(countdownInSeconds);
};

export default useCountdown;
