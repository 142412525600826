import styled from "styled-components";
import { PropsWithChildren } from "react";
import { BG_IMAGES } from "src/shared/common/constants/bgImages";

function getBG(loading: string | undefined) {
  return loading ? BG_IMAGES.placeholder : BG_IMAGES.normal;
}

export const Container = styled.div<PropsWithChildren<{ loading?: string }>>`
  display: flex;
  min-height: 100vh;
  position: relative;
  flex-direction: column;
  background-repeat: no-repeat;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background-position: center 5.25rem;
  background-image: url(${({ loading }) => getBG(loading)});
  ${({ theme }) =>
    theme.mediaWidth.upToSmall`background-position: center 8rem;`}
`;

export const Pages = styled.div`
  flex: 1;
  z-index: 1;
  max-width: 100vw;
  position: relative;
  flex-direction: column;
`;
