import { TIMESTAMP } from "../constants";
import { formatAmount } from "./formatAmount";
import zeroRemover from "./zeroRemover";

export const formatCountdown = (diffInSeconds: number) => {
  if (diffInSeconds <= 1) {
    return {
      days: formatAmount(0),
      hours: formatAmount(0),
      minutes: formatAmount(0),
      seconds: formatAmount(0),
    };
  }

  const days = Math.floor(diffInSeconds / TIMESTAMP.ONE_DAY);

  const hours = Math.floor(
    (diffInSeconds - days * TIMESTAMP.ONE_DAY) / TIMESTAMP.ONE_HOUR
  );

  const minutes = Math.floor(
    (diffInSeconds - days * TIMESTAMP.ONE_DAY - hours * TIMESTAMP.ONE_HOUR) /
      TIMESTAMP.ONE_MINUTE
  );

  const seconds =
    diffInSeconds -
    days * TIMESTAMP.ONE_DAY -
    hours * TIMESTAMP.ONE_HOUR -
    minutes * TIMESTAMP.ONE_MINUTE;

  return {
    days: zeroRemover(formatAmount(days)),
    hours: formatAmount(hours),
    minutes: formatAmount(minutes),
    seconds: formatAmount(seconds),
  };
};
