import { v4 as uuid } from "uuid";
import { NUMBER_PLACEHOLDER_CARD } from "src/shared/common/constants";
import Round from "src/shared/components/Round/Round";
import { useAppSelector } from "src/hooks/redux/useAppSelector";
import { getSales, getSalesLoading } from "src/store/slices/sales/selectors";
import ModalActionRow from "src/shared/components/ModalActionRow/ModalActionRow";
import SmallSkeleton from "src/shared/components/Placeholder/SmallSkeleton/SmallSkeleton";
import { Container, RoundsWrapper } from "./styles";

export default function Home() {
  const sales = useAppSelector(getSales);
  const loading = useAppSelector(getSalesLoading);

  return (
    <Container>
      <ModalActionRow loading={loading} />
      <RoundsWrapper>
        {loading &&
          NUMBER_PLACEHOLDER_CARD.map((index: number) => (
            <SmallSkeleton key={index} />
          ))}
        {!loading &&
          sales.length > 0 &&
          sales.map((sale) => (
            <Round key={`${uuid()}-${sale.saleId}`} sale={sale} />
          ))}
      </RoundsWrapper>
      <div />
    </Container>
  );
}
