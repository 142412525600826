import Translate from "../Translate/Translate";
import { TranslationKeys } from "src/services/i18n/i18n";
import { ErrorWarningStyled } from "./styles";
import { AmountInputError } from "./interface";

export default function ErrorWarning({ reason }: AmountInputError) {
  return (
    <ErrorWarningStyled>
      {reason && <Translate value={reason as TranslationKeys} />}
    </ErrorWarningStyled>
  );
}
