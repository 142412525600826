import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/services/i18n/i18n";

const Translate: React.FC<{
  value: TranslationKeys;
  interpolation?: object;
}> = ({ value, interpolation }) => {
  const [t] = useTranslation();
  return t(value, interpolation);
};
export default Translate;
