import styled from "styled-components";

export const TimerSeparatorStyled = styled.div`
  display: block;

  span {
    width: 100%;
    display: block;
    width: 0.125rem;
    height: 0.125rem;
    margin: 0 0.25rem;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.primaryGrey};

    &:first-child {
      margin-bottom: 0.25rem;
    }
  }
`;
