import { Statuses } from "src/shared/common/enums/Statuses";
import { maxValue } from "src/shared/common/helpers/maxValue";
import Translate from "../Translate/Translate";
import { AmountInputProps } from "./interface";
import { enforcer, validateValue } from "./helpers";
import {
  Currency,
  FieldBox,
  FieldWrapper,
  MaxValueBtn,
  MoneyInput,
  TokenAccountRow,
  TokenBalance,
  WalletIcon,
  Wrapper,
} from "./styles";
import { BigNumber } from "ethers";
import { useAppSelector } from "src/hooks/redux/useAppSelector";
import { getSaleAccountData } from "src/store/slices/sales/selectors";
import { useWalletServiceContext } from "src/contexts/WalletProvider/WalletProvider";

export default function AmountInputContainer({
  participantData,
  currentSale,
  setAmount,
  // purchase,
  amount,
  // claimValue,
  // refundValue,
  error,
  setError,
  setInputError,
  isParticipant,
}: AmountInputProps) {
  console.log("Handle change");
  const { account } = useWalletServiceContext();
  const currentSaleTokenData = useAppSelector(getSaleAccountData);
  const balance = currentSaleTokenData ? currentSaleTokenData.balance : 0;

  const handlerChange = ({ target }: { target: HTMLInputElement }) => {
    const value = enforcer(target);
    const validateAmount = validateValue({
      isParticipant,
      value,
      availableBalance: balance,
      min: BigNumber.from(currentSale.minDeposit),
      max: BigNumber.from(currentSale.maxDeposit),
      deposited: participantData?.depositedAmount || 0,
      decimal: currentSale.depositToken.decimals,
    });
    setAmount(value ?? "");
    setError(validateAmount ?? { valid: true, reason: null });
    setInputError(validateAmount ?? { valid: true, reason: null });
  };

  const calculateMaxValue = () => {
    if (BigNumber.from(balance).eq(BigNumber.from(0))) return;

    if (BigNumber.from(balance).gte(BigNumber.from(currentSale.maxDeposit))) {
      setAmount(BigNumber.from(currentSale.maxDeposit).toString());
    } else {
      setAmount(BigNumber.from(balance).toString());
    }

    // setAmount(maxValueAmount);
    // setError(validateAmount ?? { valid: true, reason: null });
  };

  return (
    <Wrapper>
      <FieldBox>
        <TokenAccountRow>
          <TokenBalance>
            <WalletIcon />
            {/* TODO: Redo */}
            {account ? balance : "0"}{" "}
            {/* {publicKey ? balance.toPrecision(6).toString() : "0"}{" "} */}
            {currentSale.depositToken.symbol}
          </TokenBalance>
          {currentSale.status === Statuses.ACTIVE && (
            <MaxValueBtn
              onClick={calculateMaxValue}
              disabled={currentSale.status !== Statuses.ACTIVE || !account}
            >
              <Translate value="Swap.Max" />
            </MaxValueBtn>
          )}
        </TokenAccountRow>
        <FieldWrapper>
          <MoneyInput
            value={amount}
            valid={error.valid}
            inputMode="decimal"
            autoComplete="off"
            autoCorrect="off"
            type="text"
            placeholder={"0.0"}
            onChange={handlerChange}
            disabled={currentSale.status !== Statuses.ACTIVE || !account}
          />
          <Currency>{currentSale.depositToken.symbol}</Currency>
        </FieldWrapper>
      </FieldBox>
    </Wrapper>
  );
}
