import styled from "styled-components";
import { ReactComponent as BocaChica } from "src/shared/assets/logo/boca-logo.svg";

export const LeftRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Toggle = styled.button<{ isOpen?: boolean }>`
  z-index: 10;
  border: none;
  cursor: pointer;
  width: 3.125rem;
  overflow: hidden;
  transition: none;
  height: 1.3125rem;
  display: inline-flex;
  flex-direction: column;
  background: transparent;
  justify-content: space-around;

  &:focus {
    outline: none;
  }

  div {
    min-height: 7px;
    position: relative;
    transform-origin: 1px;
    border-radius: 0.625rem;
    transition: width 200ms ease-out, height 0.3s linear, transform 0.3s linear,
      opacity 300ms linear;

    span {
      height: 3px;
      display: block;
      margin: 0.125rem 0;
      border-radius: 0.3125rem;
      background: ${({ theme }) => theme.colors.globalWhite};
    }

    &:first-child {
      width: ${({ isOpen }) => (isOpen ? "1.375rem" : "1.25rem")};
      transform: ${({ isOpen }) => (isOpen ? "rotate(45deg)" : "rotate(0)")};
    }

    &:nth-child(2) {
      width: ${({ isOpen }) => (isOpen ? "0" : "1.25rem")};
    }

    &:nth-child(3) {
      width: ${({ isOpen }) => (isOpen ? "1.375rem" : "1.25rem")};
      transform: ${({ isOpen }) => (isOpen ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`;

export const BocaChicaLogo = styled(BocaChica)<{ loading?: string }>`
  cursor: pointer;
  opacity: ${({ loading }) => (loading ? "0" : "1")};
`;
