import useCountdown from "src/hooks/useCountdown";
import Translate from "../Translate/Translate";
import TimerSeparator from "../Timer/components/TimerSeparator/TimerSeparator";
import { TimestampStyled } from "./styles";
import { TimestampProps } from "./interface";

export default function Timestamp({ time }: TimestampProps) {
  const countdown = useCountdown(time);

  return (
    <TimestampStyled>
      <span className="days">
        <Translate
          value="General.Time.Days"
          interpolation={{ days: countdown.days }}
        />
      </span>
      <span>{countdown.hours}</span>
      <TimerSeparator />
      <span>{countdown.minutes}</span>
      <TimerSeparator />
      <span>{countdown.seconds}</span>
    </TimestampStyled>
  );
}
