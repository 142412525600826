import { Dispatch, SetStateAction } from "react";
import { ethers, providers, Signer } from "ethers";
import abi from "src/services/configs/abi.json";
import {
  environment,
  metamaskInstallerLink,
} from "src/shared/common/constants/common";
import { networkConfig } from "src/services/configs/networkConfig";
import { BCDeimosContract } from "src/services/contract/BCDeimosContract";
import { openLinkInNewTab } from "src/shared/common/helpers/openLinkInNewTab";
import { EWalletMethods } from "./enums";

export function initializeProvider(account: string) {
  if (account) {
    return new ethers.providers.Web3Provider(window.ethereum);
  }
  return new ethers.providers.JsonRpcProvider(
    networkConfig[environment as keyof typeof networkConfig].rpcUrls[0]
  );
}

export function updateEthers(
  address: string | null,
  account: string,
  setProvider: Dispatch<SetStateAction<providers.JsonRpcProvider | null>>,
  setSigner: Dispatch<SetStateAction<Signer | null>>,
  setContract: Dispatch<SetStateAction<BCDeimosContract | null>>
) {
  if (!address) return;

  const newProvider = initializeProvider(account);
  const newSigner = newProvider.getSigner();
  const newContract = account
    ? new ethers.Contract(address, abi, newSigner)
    : new ethers.Contract(address, abi, newProvider);

  setProvider(newProvider);
  setSigner(newSigner);
  setContract(newContract as BCDeimosContract);
}

export async function changeNetwork() {
  try {
    if (!window.ethereum) openLinkInNewTab(metamaskInstallerLink);
    if (!environment) return;

    await window.ethereum.request({
      method: EWalletMethods.ADD_ETHEREUM_CHAIN,
      params: [networkConfig[environment as keyof typeof networkConfig]],
    });
  } catch (error) {
    console.error(`Error while ${EWalletMethods.ADD_ETHEREUM_CHAIN}`, error);
  }
}
