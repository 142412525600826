import styled from "styled-components";
import { ReactComponent as External } from "src/shared/assets/icons/external-link.svg";

export const Container = styled.div`
  margin: 0 auto;
  max-width: 43rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`width: 100%`}
`;

export const Wrapper = styled.div`
  padding: 1rem;
  border-radius: 1rem;
  background: ${({ theme }) => theme.colors.globalWhite};
  box-shadow: 0 0.25rem 0.5rem -0.25rem ${({ theme }) => theme.colors.shadowWrapper};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 0;
    padding: 1rem 1rem 5rem;
 `}
`;

export const Approve = styled.div`
  display: flex;
  margin-top: 1.5rem;
  align-items: start;
  position: relative;
`;

export const AcceptInput = styled.input`
  outline: none;
  display: block;
  cursor: pointer;
  appearance: none;
  border-radius: 10%;
  position: relative;
  min-width: 0.875rem;
  min-height: 0.875rem;
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.colors.darkGrey};
  transition: background 400ms cubic-bezier(0.25, 0.8, 0.25, 1),
    box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);

  &:checked {
    background: transparent;
    color: ${({ theme }) => theme.colors.darkGrey};
    border: 1px solid ${({ theme }) => theme.colors.darkGrey};

    &::before {
      opacity: 1;
    }
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 8%;
    left: 0;
    right: 0;
    opacity: 0;
    margin: auto;
    width: 0.3rem;
    height: 0.5rem;
    border-style: solid;
    transform: rotate(40deg);
    border-width: 0 2px 2px 0;
    border-color: ${({ theme }) => theme.colors.darkGrey};
  }
`;

export const ApproveDescription = styled.div`
  font-weight: 400;
  font-size: 0.75rem;
  padding-left: 0.813rem;
  font-family: "Inter", sans-serif;
  color: ${({ theme }) => theme.colors.lightGrey};
`;

export const InfoWrapper = styled.div`
  padding: 0 1rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`padding: 0;`}
`;

export const ActionContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  ${({ theme }) => theme.mediaWidth.upToSmall`flex-wrap: wrap;`}
`;

export const DeadlineWrapper = styled.div`
  display: flex;
  margin-top: 2rem;
  justify-content: space-between;

  span {
    font-weight: 500;
    font-size: 0.75rem;
    font-family: "Inter", sans-serif;
    color: ${({ theme }) => theme.colors.primaryGrey};
  }

  p {
    font-size: 1rem;
    font-weight: 600;
    padding-top: 0.375rem;
    font-family: "Inter", sans-serif;
    color: ${({ theme }) => theme.colors.globalBlack};
  }
`;

export const Description = styled.div`
  margin-top: 3rem;
  font-weight: 400;
  line-height: 140%;
  font-size: 0.875rem;
  font-family: "Inter", sans-serif;
  color: ${({ theme }) => theme.colors.lightGrey};
  ${({ theme }) => theme.mediaWidth.upToSmall`margin-top: 2.25rem;`}
`;

export const SocialWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 4.375rem;
  justify-content: space-between;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    justify-content: center;
    & div {
        margin-top: 1.938rem;
    }
    `}

  p {
    font-size: 1rem;
    font-weight: 600;
    margin-right: 0.938rem;
    font-family: "Inter", sans-serif;
    color: ${({ theme }) => theme.colors.globalGreen};
  }
`;

export const ExternalLink = styled(External)`
  width: 1.5rem;
  height: 1.5rem;
`;

export const SocialBox = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    height: 3rem;
    justify-content: center;
    order: -1;
    background: ${theme.colors.greenBgBtn};
    border-radius: 0.5rem;
  `}

  svg {
    path {
      stroke: ${({ theme }) => theme.colors.globalGreen};
    }
  }
`;
