import styled from "styled-components";

export const FooterStyled = styled.div<{ loading?: string }>`
  width: 43rem;
  display: flex;
  padding: 0 1rem;
  max-width: 100%;
  font-weight: 400;
  margin: 3rem auto 1.5rem auto;
  justify-content: space-between;
  font-family: "Inter", sans-serif;
  opacity: ${({ loading }) => (loading ? 0 : 1)};
  ${({ theme }) => theme.mediaWidth.upToSmall`padding: 0 5.5rem;`}
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`padding: 0 2rem;`}

  & > p {
    margin: 0;
    font-weight: 400;
    font-size: 0.75rem;
    color: ${({ theme }) => theme.colors.globalGrey};
  }
`;
