import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import commonEn from "src/shared/assets/locales/en/translation.json";

const resources = {
  en: {
    translation: commonEn,
  },
};

type TranslationKeysType = `${keyof typeof commonEn}`;
export type TranslationKeys =
  | TranslationKeysType
  | `${TranslationKeysType}.${string}`;

i18n.use(initReactI18next).init({
  lng: "en",
  debug: false,
  fallbackLng: "en",
  interpolation: { escapeValue: false },
  returnObjects: true,
  keySeparator: ".",
  resources,
});

export default i18n;
