import styled from "styled-components";

export const Container = styled.div`
  margin: 0 auto;
  max-width: 43rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`padding: 0 4.5rem;`}
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`padding: 0 1rem;`}
`;

export const RoundsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 1.75rem;
`;
