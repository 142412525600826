import styled from "styled-components";

export const RoundInfoItemStyled = styled.div`
  display: flex;
  align-items: center;

  div {
    &:first-child {
      width: 16rem;
      margin-right: 1.5rem;
    }
    &:last-child {
      line-height: 0;
    }

    ${({ theme }) => theme.mediaWidth.upToSmall`
      &:first-child {
        width: 100%;
      }

      &:last-child {
        width: 1.5rem;
      }
    `}
  }
`;
