import { configureStore } from "@reduxjs/toolkit";
import salesReducer from "./slices/sales/sales";

const store = configureStore({
  reducer: {
    sales: salesReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["sales/getSales/fulfilled"],
        ignoredPaths: ["sales"],
      },
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
