import { calculatePrice } from "src/shared/common/helpers/calculatePrice";
import Translate from "../Translate/Translate";
import { ValueSaleModel } from "./interface";
import { getValuesSaleData } from "./helpers";
import { Data, Item, Title, Wrapper } from "./styles";

export default function ValueSale({ currentSale, purchase }: ValueSaleModel) {
  const { deposited, target, minDep, maxDep } = getValuesSaleData(currentSale);

  return (
    <Wrapper>
      <Item>
        <Title>
          <Translate value="General.Home.Sale.Deposited" />
        </Title>
        <Data>
          <Translate
            value="General.Home.Sale.DepositedValue"
            interpolation={{
              value: deposited,
              symbol: currentSale.depositToken.symbol,
            }}
          />
        </Data>
      </Item>

      <Item>
        <Title>
          <Translate value="General.Home.Sale.Purchased" />
        </Title>
        <Data>
          {purchase} {currentSale.distributeToken.symbol}
        </Data>
      </Item>

      <Item>
        <Title>
          <Translate value="General.Home.Sale.MinAllocation" />
        </Title>
        <Data>
          {minDep} {currentSale.depositToken.symbol}
        </Data>
      </Item>

      <Item>
        <Title>
          <Translate value="General.Home.Sale.MaxAllocation" />
        </Title>
        <Data>
          {maxDep} {currentSale.depositToken.symbol}
        </Data>
      </Item>

      <Item>
        <Title>
          <Translate
            value="General.Home.Sale.Price"
            interpolation={{ symbol: currentSale.distributeToken.symbol }}
          />
        </Title>
        <Data>
          <Translate
            value="General.Home.Sale.PriceValue"
            interpolation={{
              value: calculatePrice(
                currentSale.targetDeposit,
                currentSale.targetDistribute
              ),
              symbol: currentSale.depositToken.symbol,
            }}
          />
        </Data>
      </Item>

      <Item>
        <Title>
          <Translate value="General.Home.Sale.Target" />
        </Title>
        <Data>
          {target} {currentSale.depositToken.symbol}
        </Data>
      </Item>
    </Wrapper>
  );
}
