import { Statuses } from "src/shared/common/enums/Statuses";
import { useWalletServiceContext } from "src/contexts/WalletProvider/WalletProvider";
import Translate from "../Translate/Translate";
import { ArrowBtnIcon, Button, WalletIcon } from "./styles";
import { HandleSaleActionButtonProps } from "./interface";

export default function HandleSaleActionButton({
  currentSale,
  deposit,
  error,
  value,
}: HandleSaleActionButtonProps) {
  const { account, connectWallet } = useWalletServiceContext();

  if (account) {
    return (
      <Button
        disabled={
          currentSale.status !== Statuses.ACTIVE || !error.valid || !value
        }
        onClick={deposit}
      >
        <ArrowBtnIcon />
        <Translate value="Swap.Deposit" />
      </Button>
    );
  }

  return (
    <Button onClick={connectWallet}>
      <WalletIcon />
      <Translate value="General.Header.ConnectWallet" />
    </Button>
  );
}
