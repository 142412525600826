import {
  Container,
  Icon,
  IconRow,
  ImageContainer,
  PriceName,
  PriceValue,
  SideWrapper,
  Stage,
  Title,
} from "./styles";

export default function SmallSkeleton() {
  return (
    <Container>
      <ImageContainer />
      <SideWrapper>
        <div>
          <Title />
          <IconRow>
            <Icon />
            <Stage />
          </IconRow>
          <PriceName />
          <PriceValue />
        </div>
        <div>
          <PriceName />
          <PriceValue />
        </div>
      </SideWrapper>
    </Container>
  );
}
