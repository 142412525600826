import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 3rem;
  justify-content: space-between;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 1.5rem;
  `}
`;

export const Item = styled.div`
  display: flex;
  flex: 0 0 9.813rem;
  flex-direction: column;

  ${({ theme }) => theme.mediaWidth.upToSmall`
      width: unset;
      flex: 50%
    `}

  &:nth-child(n+3) {
    ${({ theme }) => theme.mediaWidth.upToSmall`
      margin-top: 1.5rem;
    `}
  }

  &:nth-of-type(3n + 0) {
    text-align: right;
    ${({ theme }) => theme.mediaWidth.upToSmall`
      text-align: left;
    `}
  }
  &:nth-of-type(2),
  &:nth-of-type(5) {
    text-align: center;
    ${({ theme }) => theme.mediaWidth.upToSmall`
      text-align: right;
    `}
  }

  &:nth-last-child(-n + 3) {
    padding-top: 1.5rem;
    ${({ theme }) => theme.mediaWidth.upToSmall`
      padding-top: 0;
      text-align: right;
    `}
  }

  div:nth-child(4n + 2),
  div:nth-child(4n + 3) {
    ${({ theme }) => theme.mediaWidth.upToSmall`
      text-align: right;
    `}
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
      text-align: left;
    `}

  &:nth-last-child(2) {
    ${({ theme }) => theme.mediaWidth.upToSmall`
      text-align: left;
    `}
  }
`;

export const Title = styled.span`
  font-weight: 500;
  font-size: 0.75rem;
  font-family: "Inter", sans-serif;
  color: ${({ theme }) => theme.colors.primaryGrey};
`;

export const Data = styled.span`
  font-weight: 600;
  font-size: 0.75rem;
  padding-top: 0.375rem;
  font-family: "Inter", sans-serif;
  color: ${({ theme }) => theme.colors.globalBlack};
`;
