import { createGlobalStyle } from "styled-components";
import { Theme } from "./theme";

export const GlobalStyle = createGlobalStyle<{ theme: Theme }>`
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    :root {
      color: white;
      font-size: 16px;
    }

    body {
      position: relative;
      display: flex;
      min-height: 100vh;
      flex-direction: column;
      background-color: #131313;
      min-height: -webkit-fill-available;
    }
    
    html {
      height: -webkit-fill-available;
    }

    html, body {
      img {
          max-width: 100%;
        }
    }

    p {
      margin: 0;
    }

    @media only screen and (max-width: 37.5rem) {
      #root {
        background-size: 35.375rem;
      }
    }
`;
