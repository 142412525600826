import { useNavigate } from "react-router-dom";
import { formatUnits } from "ethers/lib/utils";
import { differenceInMilliseconds } from "date-fns";
import { calculatePrice } from "src/shared/common/helpers/calculatePrice";
import Translate from "../Translate/Translate";
import SaleLayoutInfo from "../SaleLayoutInfo/SaleLayoutInfo";
import RoundInfoItem from "./components/RoundInfoItem/RoundInfoItem";
import { RoundProps } from "./interface";
import {
  CurrencyValue,
  NameValue,
  NavigationRow,
  RoundItem,
  RoundOpened,
} from "./styles";
import { Statuses } from "src/shared/common/enums/Statuses";

export default function Round({ sale }: RoundProps) {
  const navigate = useNavigate();
  const target = formatUnits(sale.targetDeposit, sale.depositToken.decimals);

  return (
    <RoundItem onClick={() => navigate(`/${sale.saleId}`)}>
      {sale.status === Statuses.ACTIVE && <RoundOpened />}
      <SaleLayoutInfo sale={sale} />
      <NavigationRow>
        <div>
          <NameValue>
            <Translate
              value="General.Home.Sale.Price"
              interpolation={{ symbol: sale.distributeToken.symbol }}
            />
          </NameValue>
          <CurrencyValue>
            <span>
              <Translate
                value="General.Home.Sale.PriceValue"
                interpolation={{
                  value: calculatePrice(
                    sale.targetDeposit,
                    sale.targetDistribute
                  ),
                  symbol: sale.depositToken.symbol,
                }}
              />
            </span>
          </CurrencyValue>
        </div>
        <div>
          <NameValue>
            <Translate value="General.Home.Sale.Target" />
          </NameValue>
          <CurrencyValue>
            <Translate
              value="General.Home.Sale.TargetValue"
              interpolation={{
                value: target,
                symbol: sale.depositToken.symbol,
              }}
            />
          </CurrencyValue>
        </div>
        <RoundInfoItem
          endDate={sale.endDate}
          startDate={sale.startDate}
          depositToken={sale.depositToken}
          isHome
          saleStatus={sale.status}
          targetDeposit={sale.targetDeposit}
        />
      </NavigationRow>
    </RoundItem>
  );
}
