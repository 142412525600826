import { BigNumber, ContractTransaction, Signer } from "ethers";
import { Provider } from "@ethersproject/abstract-provider";
import { decimalToWei } from "src/shared/common/helpers/decimalToWei";
import { SaleTypeEnum } from "src/shared/common/enums/SaleTypeEnum";
import { BCDeimosContract } from "src/services/contract/BCDeimosContract";
import { ERC20Token__factory } from "src/services/contract/ERC20Token__factory";
import { contractAddress } from "src/services/configs/contractAddress";
import { ONE_SECOND } from "src/shared/common/constants/numberValues";
import { Statuses } from "src/shared/common/enums/Statuses";
import {
  Sale,
  SaleDataById,
  SaleMetadata,
} from "src/shared/common/interfaces/Sale";

async function getParticipant(
  saleId: number,
  account: string | null,
  contract: BCDeimosContract
) {
  if (!contract || !account) return null;

  try {
    const participant = await contract.getParticipant(saleId, account);

    return {
      participantAddress: participant.participantAddress,
      depositedAmount: participant.depositedAmount,
      claimedAmount: participant.claimedAmount,
      isRefunded: participant.isRefunded,
    };
  } catch (error) {
    console.error("Error while getting participant: ", error);
    return null;
  }
}

export function getSalesCount(
  contract: BCDeimosContract
): Promise<number> | number {
  try {
    return contract.getSaleCount();
  } catch (error) {
    console.error("Error while getting sales count: ", error);
    return 0;
  }
}

export async function getSale(
  saleId: number,
  provider: Provider,
  contract: BCDeimosContract,
  account: string | null
): Promise<SaleDataById | null> {
  if (!saleId || !provider || !contract) return null;

  try {
    const [saleData, metaData, participant] = await Promise.all([
      contract.getSale(saleId),
      contract.getMetadata(saleId),
      getParticipant(saleId, account, contract),
    ]);

    if (!saleData || !metaData) return null;

    const depositTokenData = ERC20Token__factory.connect(
      saleData.depositToken,
      provider
    );

    const [depositTokenSymbol, depositTokenDecimals] = await Promise.all([
      depositTokenData.symbol(),
      depositTokenData.decimals(),
    ]);

    const distributeToken = ERC20Token__factory.connect(
      saleData.distributeToken,
      provider
    );

    const [distributeTokenSymbol, distributeTokenDecimals] = await Promise.all([
      distributeToken.symbol(),
      distributeToken.decimals(),
    ]);

    return {
      status: detectSaleStatus(
        saleData.startDate.toNumber(),
        saleData.endDate.toNumber()
      ),
      startDate: saleData.startDate.toNumber(),
      endDate: saleData.endDate.toNumber(),
      saleId: saleId,
      targetDeposit: saleData.targetDeposit,
      targetDistribute: saleData.targetDistribute,
      saleType: Object.values(SaleTypeEnum)[Number(saleData.saleType)],
      currentDeposit: saleData.currentDeposit,
      minDeposit: saleData.minDeposit,
      maxDeposit: saleData.maxDeposit,
      depositToken: {
        address: saleData.depositToken,
        icon: metaData.depositLogoURL,
        decimals: depositTokenDecimals,
        symbol: depositTokenSymbol,
      },
      distributeToken: {
        address: saleData.distributeToken,
        symbol: distributeTokenSymbol,
        decimals: distributeTokenDecimals,
      },
      distributeSchedule: saleData.distributeSchedule,
      project: {
        name: metaData.name,
        medium: metaData.projectMedium,
        telegram: metaData.projectTelegram,
        twitter: metaData.projectTwitter,
        description: metaData.description,
      },
      participant: participant,
    };
  } catch (error) {
    console.error("Error while getting sale: ", error);
    return null;
  }
}

export async function deposit(
  depositTokenAddress: string,
  depositAmount: number,
  depositTokenDecimals: number,
  saleId: number,
  contract: BCDeimosContract,
  signer: Signer,
  environment: string,
  account: string
): Promise<ContractTransaction | undefined> {
  if (!contract || !signer || !environment) return;

  try {
    const token = ERC20Token__factory.connect(depositTokenAddress, signer);

    await token.approve(
      contractAddress[environment as keyof typeof contractAddress],
      decimalToWei(depositAmount, depositTokenDecimals)
    );

    await token.allowance(
      account,
      contractAddress[environment as keyof typeof contractAddress]
    );

    const transaction = await contract.deposit(
      BigNumber.from(saleId),
      decimalToWei(depositAmount, depositTokenDecimals)
    );

    return transaction;
  } catch (error) {
    console.error("Error while deposit: ", error);
    return undefined;
  }
}

export function sortSales(sales: Array<SaleDataById>) {
  sales.sort((a: { status: string }, b: { status: string }) => {
    if (
      b.status === Statuses.ACTIVE ||
      (b.status === Statuses.PENDING && a.status !== Statuses.ACTIVE)
    ) {
      return 1;
    }
    return -1;
  });
}

export const detectSaleStatus = (startDate: number, endDate: number) => {
  const nowDate = Math.floor(new Date().getTime() / ONE_SECOND);
  if (nowDate < startDate) {
    return Statuses.PENDING;
  }
  if (nowDate > startDate && nowDate < endDate) {
    return Statuses.ACTIVE;
  }
  if (nowDate > endDate) {
    return Statuses.FINISHED;
  }
  return Statuses.FINISHED;
};
