import styled from "styled-components";

import { ReactComponent as CloseIcon } from "src/shared/assets/icons/close-modal.svg";
import { ReactComponent as UserIcon } from "src/shared/assets/icons/user.svg";

export const Header = styled.div`
  display: flex;
  font-weight: 600;
  align-items: center;
  margin-bottom: 1.5rem;
  justify-content: space-between;
  font-family: "Inter", sans-serif;
  color: ${({ theme }) => theme.colors.globalBlack};

  & > p {
    margin: 0;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
`;

export const Close = styled(CloseIcon)`
  width: 14px;
  height: 14px;
  fill: black;
  cursor: pointer;
`;

export const WalletWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;

  & > p {
    font-size: 1rem;
    font-weight: 600;
    margin-left: 1rem;
    line-height: 1.375rem;
    font-family: "Inter", sans-serif;
    color: ${({ theme }) => theme.colors.globalBlack};
  }
`;

export const BalanceWrapper = styled.div`
  display: flex;
  margin: 0 0.5rem 2rem;
  flex-direction: column;

  & > div {
    &:first-child {
      margin-bottom: 1.5rem;
    }
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > p {
    margin: 0;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.063rem;
    font-family: "Inter", sans-serif;
    color: ${({ theme }) => theme.colors.globalBlack};
  }
  & > p {
    &:first-child {
      font-weight: 500;
      font-family: "Inter", sans-serif;
      color: ${({ theme }) => theme.colors.primaryGrey};
    }
  }
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  & > button {
    width: 100%;
    height: 100vh;
  }

  & > button {
    &:first-child {
      margin-bottom: 1rem;
    }
  }
`;

export default UserIcon;
