import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "src/shared/common/constants/routes";
import { LeftHeaderSideProps } from "./interface";
import { BocaChicaLogo, LeftRow, Toggle } from "./styles";

export default function LeftHeaderSide({
  isOpened,
  setIsOpened,
  checkLoading,
}: LeftHeaderSideProps) {
  const navigate = useNavigate();

  return (
    <LeftRow>
      <Toggle isOpen={isOpened} onClick={() => setIsOpened(!isOpened)}>
        <div>
          <span />
        </div>
        <div>
          <span />
        </div>
        <div>
          <span />
        </div>
      </Toggle>
      <BocaChicaLogo
        loading={checkLoading}
        onClick={() => navigate(APP_ROUTES.HOME)}
      />
    </LeftRow>
  );
}
