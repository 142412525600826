import styled from "styled-components";

export const DeadlineWrapper = styled.div`
  display: flex;
  margin-top: 2rem;
  justify-content: space-between;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 1.5rem;
  `}

  span {
    font-weight: 500;
    font-size: 0.75rem;
    font-family: "Inter", sans-serif;
    color: ${({ theme }) => theme.colors.primaryGrey};
  }

  p {
    font-size: 1rem;
    font-weight: 600;
    padding-top: 0.375rem;
    font-family: "Inter", sans-serif;
    color: ${({ theme }) => theme.colors.globalBlack};
  }

  div {
    &:last-child {
      text-align: right;
    }
  }
`;
