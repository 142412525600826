import Timer from "src/shared/components/Timer/Timer";
import { Statuses } from "src/shared/common/enums/Statuses";
import { RoundInfoItemProps } from "./interface";
import { Arrow } from "../../styles";
import { RoundInfoItemStyled } from "./styles";

export default function RoundInfoItem({
  saleStatus,
  startDate,
  endDate,
  isHome,
}: RoundInfoItemProps) {
  return (
    <RoundInfoItemStyled>
      {saleStatus !== Statuses.FINISHED ? (
        <Timer
          status={saleStatus}
          startDate={startDate}
          endDate={endDate}
          isHome={isHome}
        />
      ) : (
        <div />
      )}
      <div>
        <Arrow />
      </div>
    </RoundInfoItemStyled>
  );
}
