import React from "react";
import { v4 as uuid } from "uuid";
import { ReactComponent as BlockchainIconNear } from "src/shared/assets/icons/near-icon.svg";
import { ReactComponent as BlockchainIconAurora } from "src/shared/assets/icons/aurora-icon.svg";
import { ReactComponent as BlockchainIconEthereum } from "src/shared/assets/icons/ethereum-icon.svg";
import { ReactComponent as BlockchainIconSolana } from "src/shared/assets/icons/solana-icon.svg";
import { ReactComponent as BlockchainIconAuction } from "src/shared/assets/icons/auction-icon.svg";
import { blockchainDomains } from "src/shared/common/constants";
import { BlockchainImg } from "src/shared/common/enums/BlockchainImg";
import { DeimosNetworkLinksEnum } from "src/shared/common/enums/DeimosNetworksLinksEnum";
import { BlockchainDomainsProps } from "./interface";
import {
  BlockchainDomainButton,
  BlockchainDomainName,
  BlockchainDomainTumblerDeimos,
  DomainsWrapper,
} from "./styles";

const DomainImageMap: {
  [key: number]: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
} = {
  [BlockchainImg.Near]: BlockchainIconNear,
  [BlockchainImg.Solana]: BlockchainIconSolana,
  [BlockchainImg.Ethereum]: BlockchainIconEthereum,
  [BlockchainImg.Aurora]: BlockchainIconAurora,
  [BlockchainImg.Auction]: BlockchainIconAuction,
};

export default function BlockchainDomainsImage({
  loading,
}: BlockchainDomainsProps) {
  return (
    <DomainsWrapper>
      {blockchainDomains.map((domain) => {
        const { value, type, name } = domain;
        const DomainImage = DomainImageMap[type];

        return value === DeimosNetworkLinksEnum.AURORA ? (
          <BlockchainDomainTumblerDeimos
            key={`${uuid}-${value}`}
            loading={loading ? loading.toString() : undefined}
            className={"current"}
          >
            <DomainImage />
            <BlockchainDomainName>{name}</BlockchainDomainName>
          </BlockchainDomainTumblerDeimos>
        ) : (
          <BlockchainDomainButton
            key={`${uuid}-${value}`}
            href={value}
            target="_blank"
            rel="noreferrer"
            loading={loading ? loading.toString() : undefined}
          >
            <DomainImage />
            <BlockchainDomainName>{name}</BlockchainDomainName>
          </BlockchainDomainButton>
        );
      })}
    </DomainsWrapper>
  );
}
