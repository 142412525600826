import { BigNumber } from "ethers";

export function weiToDecimal(
  weiBalance: BigNumber,
  tokenDecimals: number
): number {
  return weiBalance
    .div(BigNumber.from(10).pow(BigNumber.from(tokenDecimals)))
    .toNumber();
}
