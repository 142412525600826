import styled from "styled-components";

export const Container = styled.div<{ isOpen?: boolean }>`
  top: 0;
  left: 0;
  font-weight: 600;
  min-width: 100vw;
  min-height: 100vh;
  position: absolute;
  padding-left: 2rem;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 3.188rem;
  justify-content: flex-end;
  font-family: "Inter", sans-serif;
  z-index: ${({ isOpen }) => (isOpen ? "10" : "0")};
  display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
  background-color: ${({ theme }) => theme.colors.globalBlack};

  & > div {
    &:last-child {
      margin-top: 5.25rem;
    }
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  font-size: 1rem;
  cursor: pointer;
  font-weight: 600;
  align-items: center;
  line-height: 1.188rem;
  margin-bottom: 2.375rem;
  color: ${({ theme }) => theme.colors.globalWhite};

  & > svg {
    margin-right: 0.875rem;
  }
`;

export const Line = styled.div`
  height: 1px;
  opacity: 0.4;
  width: 19.5rem;
  margin-bottom: 2.375rem;
  background: ${({ theme }) => theme.colors.globalGrey};
`;
