import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "src/shared/common/constants/routes";
import Logotype from "src/shared/assets/images/banners/allbridge.png";
import Translate from "../Translate/Translate";
import StatusLabel from "../StatusLabel/StatusLabel";
import { SaleLayoutInfoProps } from "./interface";
import {
  CloseBtn,
  LayoutParent,
  ProjectInfoNaming,
  ProjectLayoutImg,
  ProjectName,
  SocialImg,
  StageIcon,
  StageRow,
  StageTxt,
  StatusRow,
} from "./styles";

export default function SaleLayoutInfo({ sale, isSale }: SaleLayoutInfoProps) {
  const navigate = useNavigate();
  const { project, depositToken, status, saleType } = sale;

  return (
    <>
      <LayoutParent>
        <ProjectLayoutImg bg={Logotype} />
        {isSale && <CloseBtn onClick={() => navigate(APP_ROUTES.HOME)} />}
      </LayoutParent>
      <ProjectInfoNaming>
        <div>
          <ProjectName>{project.name}</ProjectName>
          <StageRow>
            <StageIcon src={depositToken.icon} />
            <StageTxt>
              <span>
                <Translate
                  value="Sale.Stage"
                  interpolation={{ symbol: depositToken.symbol }}
                />
              </span>
              <span>•</span>
              <span>
                <Translate
                  value="Sale.Type"
                  interpolation={{ type: saleType }}
                />
              </span>
            </StageTxt>
          </StageRow>
        </div>
        <StatusRow>
          {isSale && <SocialImg />}
          <StatusLabel saleStatus={status} />
        </StatusRow>
      </ProjectInfoNaming>
    </>
  );
}
