import styled from "styled-components";
import { ReactComponent as CloseIcon } from "src/shared/assets/icons/close-icon.svg";
import { ReactComponent as SocialIcon } from "src/shared/assets/icons/social.svg";

export const LayoutParent = styled.div`
  position: relative;
`;

export const ProjectLayoutImg = styled.div<{ bg: string }>`
  width: 100%;
  height: 3rem;
  background-size: cover;
  border-radius: 0.25rem;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${({ bg }) => bg});
`;

export const CloseBtn = styled(CloseIcon)`
  right: -1%;
  top: -13.5%;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  position: absolute;
`;

export const ProjectInfoNaming = styled.div`
  display: flex;
  margin-top: 1rem;
  align-items: flex-start;
  justify-content: space-between;
`;

export const ProjectName = styled.p`
  font-weight: 600;
  font-size: 1.25rem;
  font-family: "Inter", sans-serif;
  color: ${({ theme }) => theme.colors.globalBlack};
`;

export const StageRow = styled.div`
  display: flex;
  margin-top: 0.25rem;
  align-items: center;

  span {
    &:nth-of-type(2) {
      margin: 0 0.5rem;
    }
  }
`;

export const StageIcon = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.813rem;
`;

export const SocialImg = styled(SocialIcon)`
  width: 1.25rem;
  height: 1.438rem;
  margin-right: 1.125rem;
`;

export const StageTxt = styled.div`
  font-weight: 500;
  font-size: 0.75rem;
  font-family: "Inter", sans-serif;
  color: ${({ theme }) => theme.colors.primaryGrey};
`;

export const StatusRow = styled.div`
  display: flex;
  align-items: center;
`;
