import styled from "styled-components";
import { PropsWithChildren } from "react";
import { ReactComponent as Wallet } from "src/shared/assets/icons/wallet-dep.svg";

export const Wrapper = styled.div`
  display: flex;
  margin-top: 2.563rem;
  align-items: flex-end;
  justify-content: space-between;
  ${({ theme }) => theme.mediaWidth.upToSmall`width: 100%;`}
`;

export const FieldBox = styled.div`
  width: 24.5rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`width: 100%;`}
`;

export const TokenAccountRow = styled.div`
  display: flex;
  font-weight: 400;
  font-size: 0.75rem;
  align-items: center;
  margin-bottom: 0.375rem;
  justify-content: space-between;
  font-family: "Inter", sans-serif;
  color: ${({ theme }) => theme.colors.globalBlack};
`;

export const WalletIcon = styled(Wallet)`
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
`;

export const TokenBalance = styled.div`
  display: flex;
  align-items: center;
`;

export const MaxValueBtn = styled.button`
  border: unset;
  outline: unset;
  cursor: pointer;
  font-weight: 600;
  background: transparent;
  font-family: "Inter", sans-serif;
  color: ${({ theme }) => theme.colors.globalGrey};
`;

export const FieldWrapper = styled.div`
  position: relative;
  margin-top: 0.375rem;

  & > input[type="text"] {
    -moz-appearance: textfield;
  }

  & > input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    margin: 0;
    -webkit-appearance: none;
  }
`;

export const MoneyInput = styled.input<PropsWithChildren<any>>`
  width: 100%;
  height: 3rem;
  outline: none;
  font-size: 1rem;
  font-weight: 600;
  padding-left: 1rem;
  border-radius: 0.5rem;
  background-color: unset;
  text-transform: uppercase;
  font-family: "Inter", sans-serif;
  color: ${({ theme }) => theme.colors.globalBlack};
  border: 1px solid
    ${({ theme, valid }) => {
      if (!valid) {
        return theme.colors.negative;
      }
      return theme.colors.globalGrey;
    }};
  &::placeholder {
    color: ${({ theme }) => theme.colors.globalBlack};
  }
`;

export const Currency = styled.span`
  top: 50%;
  right: 0;
  z-index: 1;
  font-size: 1rem;
  font-weight: 600;
  position: absolute;
  margin-right: 1rem;
  transform: translateY(-50%);
  font-family: "Inter", sans-serif;
  color: ${({ theme }) => theme.colors.globalBlack};
`;
