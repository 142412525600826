import { Suspense } from "react";
import { Provider } from "react-redux";
import ReactDOM from "react-dom/client";
import { ThemeProvider } from "styled-components";
import { BrowserRouter as Router } from "react-router-dom";
import store from "src/store";
import { theme } from "src/shared/styles/theme";
import { Loader } from "src/shared/components/Loader/Loader";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ModalContextProvider } from "./contexts/ModalProvider/ModalProvider";
import { WalletServiceProvider } from "./contexts/WalletProvider/WalletProvider";
import "src/services/i18n/i18n";

const root = ReactDOM.createRoot(
  document.getElementById("root") as Element | DocumentFragment
);

root.render(
  <Router>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <WalletServiceProvider>
          <Suspense fallback={<Loader />}>
            <ModalContextProvider>
              <App />
            </ModalContextProvider>
          </Suspense>
        </WalletServiceProvider>
      </ThemeProvider>
    </Provider>
  </Router>
);
reportWebVitals();
