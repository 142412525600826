import styled from "styled-components";

export const Container = styled.div`
  padding: 1rem;
  margin: 0 auto;
  width: 43.125rem;
  border-radius: 1rem;
  background: ${({ theme }) => theme.colors.globalWhite};
  box-shadow: 0 0.25rem 0.5rem -0.25rem ${({ theme }) => theme.shadowColor.shadowWrapper};

  ${({ theme }) => theme.mediaWidth.upToSmall`width: 100%`}

  &:first-child {
    margin-top: 0;
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: 3rem;
  border-radius: 0.25rem;
  background-color: ${({ theme }) => theme.colors.opacityGrey};
`;

export const Title = styled.div`
  height: 0.75rem;
  width: 8.063rem;
  margin-top: 1.375rem;
  border-radius: 0.25rem;
  background-color: ${({ theme }) => theme.colors.opacityGrey};
`;

export const IconRow = styled.div`
  display: flex;
  margin-top: 0.75rem;
  align-items: center;
`;

export const Icon = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  margin-right: 0.5rem;
  background-color: ${({ theme }) => theme.colors.opacityGrey};
`;

export const Stage = styled.div`
  height: 0.5rem;
  width: 3.938rem;
  border-radius: 0.25rem;
  background-color: ${({ theme }) => theme.colors.opacityGrey};
`;

export const InputContainer = styled.div`
  height: 3rem;
  display: flex;
  margin-top: 2rem;
  justify-content: space-between;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    height: auto;
    flex-direction: column;
 `}
`;

export const Input = styled.div`
  height: 100%;
  width: 24.625rem;
  border-radius: 0.25rem;
  border: 2px solid ${({ theme }) => theme.colors.opacityGrey};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    height: 3rem;
 `}
`;

export const Button = styled.div`
  width: 15rem;
  height: 100%;
  border-radius: 0.25rem;
  background-color: ${({ theme }) => theme.colors.opacityGrey};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    height: 3rem;
    margin-top: 1.5rem;
 `}
`;

export const InfoWrapper = styled.div`
  padding: 0 1rem 2rem 1rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`padding: 0;`}
`;

export const InfoBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 3rem;
  justify-content: space-between;
`;

export const InfoItem = styled.div`
  display: flex;
  flex: 0 0 9.813rem;
  flex-direction: column;

  ${({ theme }) => theme.mediaWidth.upToSmall`
      width: unset;
      flex: 50%
    `}

  &:nth-child(n+3) {
    ${({ theme }) => theme.mediaWidth.upToSmall`margin-top: 1.5rem;`}
  }

  &:nth-of-type(3n + 0) {
    align-items: flex-end;
    ${({ theme }) => theme.mediaWidth.upToSmall`align-items: flex-start;`}
  }

  &:nth-of-type(2),
  &:nth-of-type(5) {
    align-items: center;
    ${({ theme }) => theme.mediaWidth.upToSmall`align-items: flex-end;`}
  }

  &:nth-last-child(-n + 3) {
    margin-top: 1.5rem;
    ${({ theme }) => theme.mediaWidth.upToSmall`align-items: flex-end;`}
  }

  div:nth-child(4n + 2),
  div:nth-child(4n + 3) {
    ${({ theme }) => theme.mediaWidth.upToSmall`align-items: flex-end;`}
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`align-items: flex-start;`}

  &:nth-last-child(2) {
    ${({ theme }) => theme.mediaWidth.upToSmall`align-items: flex-start;`}
  }
`;

export const InfoName = styled.div`
  height: 0.5rem;
  width: 2.688rem;
  border-radius: 0.25rem;
  background-color: ${({ theme }) => theme.colors.opacityGrey};
`;

export const InfoValue = styled.div`
  width: 4.875rem;
  height: 0.75rem;
  margin-top: 0.75rem;
  border-radius: 0.25rem;
  background-color: ${({ theme }) => theme.colors.opacityGrey};
`;

export const TimeRow = styled.div`
  width: 100%;
  height: 1rem;
  margin-top: 3rem;
  border-radius: 0.25rem;
  background-color: ${({ theme }) => theme.colors.opacityGrey};
`;

export const TimeWrapper = styled.div`
  display: flex;
  margin-top: 1.5rem;
  justify-content: space-between;

  div {
    &:last-child {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }
`;
