import React from "react";
import { v4 as uuid } from "uuid";
import { ReactComponent as TelegramImg } from "src/shared/assets/icons/telegram-icon.svg";
import { ReactComponent as MediumImg } from "src/shared/assets/icons/medium-icon.svg";
import { ReactComponent as TwitterImg } from "src/shared/assets/icons/twitter-icon.svg";
import { Social } from "src/shared/common/enums/Social";
import { SocialNetworkProps } from "./interface";
import { SocialNetworksIcon, SocialNetworksStyled } from "./styles";

const ImageMap: {
  [key: number]: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
} = {
  [Social.Medium]: MediumImg,
  [Social.Telegram]: TelegramImg,
  [Social.Twitter]: TwitterImg,
};

export default function SocialNetwork({ socials, isSwap }: SocialNetworkProps) {
  return (
    <SocialNetworksStyled>
      {socials.map((social) => {
        const Image = ImageMap[social.type];
        return (
          <SocialNetworksIcon
            key={`${uuid()}-${social.value}`}
            href={social.value}
            target="_blank"
            rel="noreferrer"
            isSwap={isSwap}
          >
            <Image />
          </SocialNetworksIcon>
        );
      })}
    </SocialNetworksStyled>
  );
}
