import { formatUnits } from "ethers/lib/utils";
import Translate from "../Translate/Translate";
import { AmountProgressStyled } from "./styles";
import { AmountProgressProps } from "./interface";

export default function AmountProgress({
  depositToken,
  targetDeposit,
  currentDeposit,
}: AmountProgressProps) {
  const target = formatUnits(targetDeposit, depositToken.decimals);
  const deposited = formatUnits(currentDeposit, depositToken.decimals);

  return (
    <AmountProgressStyled>
      <div>
        <span>
          <Translate value="General.Home.TotalField" />
        </span>
        <span>
          <Translate
            value="General.Home.TotalFieldValue"
            interpolation={{
              deposited: deposited,
              target: target,
              symbol: depositToken.symbol,
            }}
          />
        </span>
      </div>
      <progress value={deposited} max={target} />
    </AmountProgressStyled>
  );
}
