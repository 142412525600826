import { v4 as uuid } from "uuid";
import { TranslationKeys } from "src/services/i18n/i18n";
import { ButtonSecondary } from "../../Buttons/ButtonSecondary";
import Translate from "../../Translate/Translate";
import ModalWrapper from "../ModalWrapper";
import { SignOutModalProps } from "./interface";
import UserIcon, {
  BalanceWrapper,
  Close,
  Footer,
  Header,
  Row,
  WalletWrapper,
} from "./styles";

export default function SignOutModal({
  closeModal,
  currency,
  balance,
}: SignOutModalProps) {
  const confirmHandler = async () => {
    console.log("disconnect wallet"); // TODO: add wallet function
    closeModal();
  };

  const balanceArray = [
    {
      title: "General.Modal.Balance",
      value: `balance`,
    },
  ];

  return (
    <ModalWrapper closeModal={closeModal}>
      <Header>
        <p>
          <Translate value="General.Modal.User" />
        </p>
        <Close onClick={closeModal} />
      </Header>
      <WalletWrapper>
        <UserIcon />
        {/* TODO: add wallet */}
        <p>wallet</p>
        {/* <p>{wallet}</p> */}
      </WalletWrapper>
      <BalanceWrapper>
        {balanceArray.map(({ title, value }) => (
          <Row key={`${uuid()}-${title}`}>
            <p>
              <Translate value={title as TranslationKeys} />
            </p>
            <p>{value}</p>
          </Row>
        ))}
      </BalanceWrapper>
      <Footer>
        <ButtonSecondary onClick={confirmHandler}>
          <Translate value="General.Modal.Disconnect" />
        </ButtonSecondary>
      </Footer>
    </ModalWrapper>
  );
}
