import { v4 as uuid } from "uuid";
import useDisableBodyScroll from "src/hooks/useDisableBodyScroll";
import { socials } from "src/shared/common/constants/socials";
import Translate from "../Translate/Translate";
import { TranslationKeys } from "src/services/i18n/i18n";
import SocialNetwork from "../SocialNetwork/SocialNetwork";
import { BurgerMenuProps } from "./interfaces";
import { Column, Container, Line, Row } from "./styles";
import { burgerMenuList, launchpadsList } from "./util";

function openLink(url: string) {
  window.open(url, "_blank", "noopener noreferrer");
}

export default function BurgerMenu({ isOpened }: BurgerMenuProps) {
  useDisableBodyScroll(isOpened);

  return (
    <Container isOpen={isOpened}>
      <Column>
        {burgerMenuList.map(({ title, logo: LogoComponent, link }) => (
          <Row key={`${uuid()}-${title}`} onClick={() => openLink(link)}>
            <LogoComponent />
            <Translate value={title as TranslationKeys} />
          </Row>
        ))}
      </Column>
      <Line />
      <Column>
        {launchpadsList.map(({ title, logo: LogoComponent, link }) => (
          <Row key={`${uuid()}-${title}`} onClick={() => openLink(link)}>
            <LogoComponent />
            <Translate value={title as TranslationKeys} />
          </Row>
        ))}
      </Column>
      <SocialNetwork socials={socials} />
    </Container>
  );
}
