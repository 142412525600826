import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import useWindowDimensions from "src/hooks/useWindowDimensions";
import { EModals } from "src/contexts/ModalProvider/interface";
import { useAppSelector } from "src/hooks/redux/useAppSelector";
import { Dimensions } from "src/shared/common/enums/Dimensions";
import { cropString } from "src/shared/common/helpers/cropString";
import { ModalProvider } from "src/contexts/ModalProvider/ModalProvider";
import { useWalletServiceContext } from "src/contexts/WalletProvider/WalletProvider";
import LeftHeaderSide from "./LeftHeaderSide/LeftHeaderSide";
import { ButtonConnect } from "../Buttons/ButtonConnect";
import { getSaleAccountData } from "src/store/slices/sales/selectors";
import BlockchainDomainsImage from "../BlockchainDomainsImage/BlockchainDomainsImage";
import { HeaderProps } from "./interface";
import WalletLogo, {
  ActionWrapper,
  HeaderStyled,
  MobileWrapper,
} from "./styles";

export default function Header({ isOpened, setIsOpened }: HeaderProps) {
  const pathname = location.pathname;
  const id = useMemo(
    () => pathname.substring(pathname.lastIndexOf("/"))[1],
    [pathname]
  );
  const { t } = useTranslation();
  const { showModal } = ModalProvider();
  const dimension = useWindowDimensions();
  const { account, connectWallet } = useWalletServiceContext();
  const currentSaleTokenData = useAppSelector(getSaleAccountData);

  const loading = false; //TODO: https://hapilabs.atlassian.net/jira/software/projects/BCM/boards/8?selectedIssue=BCM-246
  const checkLoading = undefined; //TODO: https://hapilabs.atlassian.net/jira/software/projects/BCM/boards/8?selectedIssue=BCM-246
  const btnTxt =
    account && currentSaleTokenData
      ? `${currentSaleTokenData.balance} ${currentSaleTokenData.symbol}`
      : account
      ? cropString(account, 4)
      : t("General.Header.ConnectWallet");

  function signOutModal() {
    showModal(EModals.SIGN_OUT_MODAL, {
      currency: currentSaleTokenData ? currentSaleTokenData.symbol : "",
      balance: currentSaleTokenData ? currentSaleTokenData.balance : "",
    });
  }

  function chooseConnectAction() {
    if (account) {
      return signOutModal();
    }

    return connectWallet();
  }

  return (
    <HeaderStyled>
      {dimension !== Dimensions.SMALL && (
        <LeftHeaderSide
          isOpened={isOpened}
          setIsOpened={setIsOpened}
          checkLoading={checkLoading}
        />
      )}
      {dimension === Dimensions.SMALL && (
        <MobileWrapper>
          <LeftHeaderSide
            isOpened={isOpened}
            setIsOpened={setIsOpened}
            checkLoading={checkLoading}
          />
          <ButtonConnect
            onClick={chooseConnectAction}
            loading={checkLoading}
            disabled={loading}
          >
            <WalletLogo />
            {btnTxt}
          </ButtonConnect>
        </MobileWrapper>
      )}

      {dimension !== Dimensions.SMALL && (
        <BlockchainDomainsImage loading={loading} />
      )}

      <ActionWrapper>
        {dimension !== Dimensions.SMALL && (
          <ButtonConnect
            onClick={chooseConnectAction}
            loading={checkLoading}
            disabled={loading}
          >
            <WalletLogo />
            {btnTxt}
          </ButtonConnect>
        )}
      </ActionWrapper>

      {dimension === Dimensions.SMALL && !id && (
        <BlockchainDomainsImage loading={loading} />
      )}
    </HeaderStyled>
  );
}
