import { useCallback, useState, useMemo, useEffect } from "react";
import debounce from "lodash/debounce";
import ModalWrapper from "../ModalWrapper";
import Translate from "../../Translate/Translate";
import SearchResultRow from "../../SearchResultRow/SearchResultRow";
import { SearchModalProps } from "./interface";
import { getSales } from "src/store/slices/sales/selectors";
import { useAppSelector } from "src/hooks/redux/useAppSelector";
import {
  MILLISECONDS_300,
  TWO,
} from "src/shared/common/constants/numberValues";
import { SaleDataById } from "src/shared/common/interfaces/Sale";
import {
  Body,
  ClearSearch,
  Close,
  Header,
  Input,
  NoResultsIcon,
  NoResultsLabel,
  NoResultsTitle,
  NoResultsWrapper,
  Search,
  StyledInput,
} from "./styles";

export function SearchModal({ closeModal }: SearchModalProps) {
  const sales = useAppSelector(getSales);
  const [searchValue, setSearchValue] = useState<string>("");
  const [filteredSales, setFilteredSales] = useState<SaleDataById[]>(sales);

  const searchHandle = useCallback(
    (search: string) => {
      if (search.length < TWO) return;

      const salesBySearch = sales.filter((sale) =>
        sale.project.name.toLowerCase().includes(search.toLowerCase())
      );
      setFilteredSales(salesBySearch);
    },
    [sales]
  );

  const debouncedSearchSales = useMemo(
    () => debounce(searchHandle, MILLISECONDS_300),
    [searchHandle]
  );

  useEffect(() => {
    debouncedSearchSales(searchValue);

    return () => debouncedSearchSales.cancel();
  }, [searchValue, sales, debouncedSearchSales]);

  return (
    <ModalWrapper closeModal={closeModal}>
      <Header>
        <StyledInput>
          <Search />
          <Input
            value={searchValue}
            onChange={(value) => setSearchValue(value.target.value)}
          />
          {searchValue && <ClearSearch onClick={() => setSearchValue("")} />}
        </StyledInput>
        <Close onClick={closeModal} />
      </Header>
      <Body>
        {filteredSales.length === 0 && (
          <NoResultsWrapper>
            <NoResultsIcon />
            <NoResultsTitle>
              <Translate
                value="NoResults.Title"
                interpolation={{ query: searchValue }}
              />
            </NoResultsTitle>
            <NoResultsLabel>
              <Translate value="NoResults.Label" />
            </NoResultsLabel>
          </NoResultsWrapper>
        )}
        <SearchResultRow close={closeModal} sales={filteredSales} />
      </Body>
    </ModalWrapper>
  );
}
