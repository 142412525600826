import { BigNumber } from "ethers";

export function decimalToWei(
  tokenAmount: number,
  tokenDecimals: number
): BigNumber {
  return BigNumber.from(tokenAmount).mul(
    BigNumber.from(10).pow(BigNumber.from(tokenDecimals))
  );
}
