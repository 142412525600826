import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { providers, Signer } from "ethers";
import {
  environment,
  metamaskInstallerLink,
} from "src/shared/common/constants/common";
import { BCDeimosContract } from "src/services/contract/index";
import { contractAddress } from "src/services/configs/contractAddress";
import { openLinkInNewTab } from "src/shared/common/helpers/openLinkInNewTab";
import { EWalletMethods } from "./enums";
import { changeNetwork, updateEthers } from "./helpers";
import { WalletContextType, WalletProviderProps } from "./interface";

declare global {
  interface Window {
    ethereum: any;
  }
}

const initialWalletState: WalletContextType = {
  account: "",
  connectWallet: () => undefined,
  contract: null,
  signer: null,
  provider: null,
  environment: undefined,
};

export const WalletServiceContext =
  createContext<WalletContextType>(initialWalletState);

export function WalletServiceProvider({ children }: WalletProviderProps) {
  const [address, setAddress] = useState<string | null>(null);
  const [account, setAccount] = useState<string>("");
  const [provider, setProvider] = useState<providers.JsonRpcProvider | null>(
    null
  );
  const [signer, setSigner] = useState<Signer | null>(null);
  const [contract, setContract] = useState<BCDeimosContract | null>(null);

  const connectWallet = useCallback(async () => {
    try {
      if (window.ethereum) {
        const accounts = await window.ethereum.request({
          method: EWalletMethods.REQUEST_ACCOUNTS,
        });

        setAccount(accounts[0]);
        changeNetwork();
      } else {
        openLinkInNewTab(metamaskInstallerLink);
      }
    } catch (error) {
      console.error(`Error while ${EWalletMethods.REQUEST_ACCOUNTS}`, error);
    }
  }, [window.ethereum]);

  useEffect(() => {
    updateEthers(address, account, setProvider, setSigner, setContract);
  }, [account, address]);

  useEffect(() => {
    if (!environment) return;

    setAddress(contractAddress[environment as keyof typeof contractAddress]);
  }, [environment]);

  return (
    <WalletServiceContext.Provider
      value={{
        connectWallet,
        account,
        contract,
        signer,
        provider,
        environment,
      }}
    >
      {children}
    </WalletServiceContext.Provider>
  );
}

export const useWalletServiceContext = () => useContext(WalletServiceContext);
