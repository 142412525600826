import { css } from "styled-components/macro";
import baseStyled, { ThemedStyledInterface } from "styled-components";

export const MEDIA_WIDTHS = {
  upToLarge: 1280,
  upToMedium: 960,
  upToSmall: 720,
  upToExtraSmall: 550,
};

export const mediaWidthTemplates: {
  [width in keyof typeof MEDIA_WIDTHS]: typeof css;
} = Object.keys(MEDIA_WIDTHS).reduce((accumulator, size) => {
  (accumulator as any)[size] = (a: any, b: any, c: any) => css`
    @media (max-width: ${(MEDIA_WIDTHS as any)[size]}px) {
      ${css(a, b, c)}
    }
  `;
  return accumulator;
}, {}) as any;

export const theme = {
  colors: {
    globalBlack: "#131313",
    globalWhite: "#FFFFFF",
    grey: "#bebebe",
    lightGrey: "#676C73",
    globalGrey: "#B5BBC2",
    primaryGrey: "#8E939A",
    globalGreen: "#1CB17C",
    negative: "#F65151",
    bgGrey: "181,187,194",
    primaryGreen: "28,177,125",
    borderBlue: "rgba(19, 19, 19, 0.2)",
    greenBgBtn: "rgba(28, 177, 124, 0.1)",
    opacityGrey: "rgba(181, 187, 194, 0.2)",
    transparent: "transparent",
  },
  statusColor: {
    pending: "#F49302",
    bgPending: "rgba(244, 147, 2, 0.2)",
    active: "#51A81B",
    activeDarken: "#458a1a",
    bgActive: "rgba(81, 168, 27, 0.2)",
    finished: "#8E939A",
    bgFinished: "rgba(142, 147, 154, 0.2)",
  },
  shadowColor: {
    shadowWrapper: "rgba(19, 19, 19, 0.2)",
  },
  transition: {
    easeAll: "0.5s ease all",
  },
  grids: {
    sm: 8,
    md: 12,
    lg: 24,
  },
  mediaWidth: mediaWidthTemplates,
  flexColumnNoWrap: css`
    display: flex;
    flex-flow: column nowrap;
  `,
  flexRowNoWrap: css`
    display: flex;
    flex-flow: row nowrap;
  `,
};

export type Theme = typeof theme;
export const styled = baseStyled as ThemedStyledInterface<Theme>;
