import React from "react";
import {
  NUMBER_PLACEHOLDER_ROW,
  NUMBER_PLACEHOLDER_VALUE,
} from "src/shared/common/constants";
import DescriptionParagraph from "../DescriptionParagraph/DescriptionParagraph";
import {
  Button,
  Container,
  Icon,
  IconRow,
  ImageContainer,
  InfoBlock,
  InfoItem,
  InfoName,
  InfoValue,
  InfoWrapper,
  Input,
  InputContainer,
  Stage,
  TimeRow,
  TimeWrapper,
  Title,
} from "./styles";

const BigSkeleton: React.FC = () => {
  return (
    <Container>
      <ImageContainer />
      <Title />

      <IconRow>
        <Icon />
        <Stage />
      </IconRow>

      <InputContainer>
        <Input />
        <Button />
      </InputContainer>

      <InfoWrapper>
        <DescriptionParagraph />
        <InfoBlock>
          {NUMBER_PLACEHOLDER_ROW.map((row, index) => (
            <InfoItem key={`row-index-${row + index}`}>
              <InfoName />
              <InfoValue />
            </InfoItem>
          ))}
        </InfoBlock>
        <TimeRow />
        <TimeWrapper>
          {NUMBER_PLACEHOLDER_VALUE.map((row, index) => (
            <InfoItem key={`row-index-${row + index}`}>
              <InfoName />
              <InfoValue />
            </InfoItem>
          ))}
        </TimeWrapper>
        <TimeRow />
        <DescriptionParagraph />
      </InfoWrapper>
    </Container>
  );
};

export default BigSkeleton;
