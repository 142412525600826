import styled from "styled-components";
import { PropsWithChildren } from "react";

export const SocialNetworksStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SocialNetworksIcon = styled.a<PropsWithChildren<any>>`
  margin: 0 1rem;

  &:first-child {
    margin-left: 0;
  }

  & > svg {
    width: ${({ isSwap }) => {
      if (isSwap) {
        return "2rem";
      }
      return "2.8rem;";
    }};
    height: ${({ isSwap }) => {
      if (isSwap) {
        return "2rem";
      }
      return "2.8rem;";
    }};
  }

  path {
    fill: ${({ theme, isSwap }) => {
      if (isSwap) {
        return theme.colors.globalBlack;
      }
      return theme.colors.globalWhite;
    }};
  }
`;
