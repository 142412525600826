import { TimerSeparatorStyled } from "./styles";

export default function TimerSeparator() {
  return (
    <TimerSeparatorStyled>
      <span></span>
      <span></span>
    </TimerSeparatorStyled>
  );
}
