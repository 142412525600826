import styled from "styled-components";

export const AmountProgressStyled = styled.div`
  margin-top: 3rem;
  font-weight: 600;
  font-size: 0.75rem;
  font-family: "Inter", sans-serif;
  color: ${({ theme }) => theme.colors.globalBlack};

  div {
    display: flex;
    justify-content: space-between;

    span {
      &:first-child {
        font-weight: 500;
        font-family: "Inter", sans-serif;
        color: ${({ theme }) => theme.colors.primaryGrey};
      }
    }
  }

  progress {
    width: 100%;
    height: 1rem;
    border: none;
    appearance: none;
    margin-top: 0.688rem;
    border-radius: 0.25rem;
    -webkit-appearance: none;

    &::-webkit-progress-bar {
      border-radius: 4px;
      background: rgba(${({ theme }) => theme.colors.primaryGreen}, 0.2);
    }

    &::-webkit-progress-value {
      background: rgba(${({ theme }) => theme.colors.primaryGreen});
    }
  }
`;
