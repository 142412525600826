import styled from "styled-components";

export const ModalWrapperStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  height: 100%;
  opacity: 0.4;
  overflow: auto;
  background-color: ${({ theme }) => theme.colors.globalBlack};
`;

export const Modal = styled.div<{
  isCentered?: boolean;
  isFullWidth?: boolean;
  isFullHeight?: boolean;
}>`
  top: 50%;
  left: 50%;
  height: auto;
  z-index: 200;
  padding: 1rem;
  display: flex;
  overflow: auto;
  position: fixed;
  max-height: 90%;
  min-width: 20.5rem;
  border-radius: 1rem;
  flex-direction: column;
  box-shadow: 0px 4px 8px -4px;
  transform: translate(-50%, -50%);
  color: ${({ theme }) => theme.colors.black};
  background: ${({ theme }) => theme.colors.globalWhite};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    top: 100%;
    width: 100%;
    height: 100vh;
    min-width: 100%;
    border-radius: "1rem 1rem 0 0";
    transform: translate(-50%, -100%);
  `}
`;
