import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  GetSaleAccountDataParams,
  GetSalesParams,
  UpdateSalesParams,
} from "src/store/slices/sales/interface";
import { Filter } from "src/shared/common/interfaces/Filter";
import { notFalsy } from "src/shared/common/helpers/notFalsy";
import { weiToDecimal } from "src/shared/common/helpers/weiToDecimal";
import { DEFAULT_FILTER } from "src/shared/common/constants/defaultFilter";
import { ERC20Token__factory } from "src/services/contract/ERC20Token__factory";
import {
  CurrentSaleTokenData,
  SaleDataById,
} from "src/shared/common/interfaces/Sale";
import assertFulfilled from "src/shared/common/helpers/assertFulfilled";
import { getSale, getSalesCount, sortSales } from "./helpers";

type SalesState = {
  salesList: Array<SaleDataById>;
  saleAccountData: CurrentSaleTokenData | null;
  loading: boolean;
  filterType: Filter;
};

const initialState: SalesState = {
  salesList: [],
  saleAccountData: null,
  loading: false,
  filterType: DEFAULT_FILTER,
};

export const getSales = createAsyncThunk<
  SaleDataById[],
  GetSalesParams,
  { rejectValue: string }
>("sales/getSales", async ({ contract, provider, account }) => {
  try {
    const salesCount: number = await getSalesCount(contract);
    const salesIds: Array<number> = Array.from(
      { length: salesCount },
      (_, i) => i + 1
    );

    const salesListDataResponse = await Promise.allSettled(
      salesIds.map(async (saleId: number) => {
        return await getSale(saleId, provider, contract, account);
      })
    );

    const salesListData = salesListDataResponse
      .filter(assertFulfilled)
      .map((item) => item.value);

    const salesListDataFinal: Array<SaleDataById> =
      salesListData.filter(notFalsy);

    sortSales(salesListDataFinal);
    return salesListDataFinal;
  } catch (error) {
    console.error(`Error while getSales`, error);
    return [];
  }
});

export const getSaleAccountData = createAsyncThunk<
  CurrentSaleTokenData | null,
  GetSaleAccountDataParams,
  { rejectValue: string }
>(
  "sales/getSaleAccountData",
  async ({ saleId, depositTokenAddress, provider, account }) => {
    try {
      if (!saleId || !depositTokenAddress || !provider || !account) {
        return null;
      }

      const token = ERC20Token__factory.connect(depositTokenAddress, provider);
      const tokenSymbol = await token.symbol();
      const tokenDecimals = await token.decimals();
      const tokenBalance = weiToDecimal(
        await token.balanceOf(account),
        tokenDecimals
      );

      return {
        balance: tokenBalance,
        symbol: tokenSymbol,
        decimals: tokenDecimals,
      };
    } catch (error) {
      console.error(`Error while getSaleAccountData`, error);
      return null;
    }
  }
);

export const salesSlice = createSlice({
  name: "sales",
  initialState,
  reducers: {
    updateSaleInList(state, action: PayloadAction<UpdateSalesParams>) {
      const { updatedSaleIndex = -1, updatedSale = null } = action.payload;

      if (updatedSale && updatedSaleIndex > -1) {
        state.salesList[updatedSaleIndex] = updatedSale;
      }
    },
    clearSaleAccountData(state) {
      state.saleAccountData = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSales.pending, (state) => {
        console.log("pending");
      })
      .addCase(getSales.fulfilled, (state, action) => {
        state.salesList = action.payload;
      })
      .addCase(getSales.rejected, (state, action) => {
        console.error("Error"); //TODO: add handle
      });
    builder
      .addCase(getSaleAccountData.pending, (state) => {
        console.log("pending");
      })
      .addCase(getSaleAccountData.fulfilled, (state, action) => {
        state.saleAccountData = action.payload;
      })
      .addCase(getSaleAccountData.rejected, (state, action) => {
        console.error("Error"); //TODO: add handle
      });
  },
});

export const { updateSaleInList, clearSaleAccountData } = salesSlice.actions;

export default salesSlice.reducer;
