import { Statuses } from "src/shared/common/enums/Statuses";
import { detectTimestamp } from "src/shared/common/helpers/detectTimestamp";
import Timestamp from "../Timestamp/Timestamp";
import Translate from "../Translate/Translate";
import { TimerStyled } from "./styles";
import { TimerProps } from "./interface";

export default function Timer({
  status,
  startDate,
  endDate,
  isHome,
}: TimerProps) {
  return (
    <TimerStyled isHome={isHome}>
      {status === Statuses.PENDING ? (
        <Translate value="General.Home.Sale.StartsIn" />
      ) : (
        <Translate value="General.Home.Sale.EndsIn" />
      )}
      <Timestamp time={detectTimestamp(startDate, endDate, status)} />
    </TimerStyled>
  );
}
