import styled from "styled-components";

export const ButtonConnect = styled.button<{ loading?: string }>`
  border: none;
  outline: none;
  display: flex;
  cursor: pointer;
  width: 8.063rem;
  height: 2.25rem;
  font-weight: 600;
  font-size: 0.75rem;
  align-items: center;
  border-radius: 0.5rem;
  line-height: 0.938rem;
  justify-content: center;
  font-family: "Inter", sans-serif;
  color: ${({ theme, loading }) =>
    loading ? theme.transparent : theme.colors.globalWhite};
  background: ${({ theme, loading }) =>
    loading ? theme.colors.opacityGrey : theme.colors.globalGreen};

  & > svg {
    margin-right: 0.5rem;
    opacity: ${({ loading }) => (loading ? "0" : "1")};
  }
`;
