import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import Footer from "src/shared/components/Footer/Footer";
import Header from "src/shared/components/Header/Header";
import BurgerMenu from "src/shared/components/BurgerMenu/BurgerMenu";
import { getSales } from "src/store/slices/sales/sales";
import { GlobalStyle } from "src/shared/styles/globalStyles";
import { APP_ROUTES } from "src/shared/common/constants/routes";
import { useAppSelector } from "src/hooks/redux/useAppSelector";
import { useAppDispatch } from "src/hooks/redux/useAppDispatch";
import { getSalesLoading } from "src/store/slices/sales/selectors";
import { useWalletServiceContext } from "src/contexts/WalletProvider/WalletProvider";
import { Container, Pages } from "./styles";
import Sale from "./screens/Sale";
import Home from "./screens/Home";

export default function App() {
  const loading = useAppSelector(getSalesLoading);
  const { contract, provider, account } = useWalletServiceContext();
  const dispatch = useAppDispatch();

  const [isOpened, setIsOpened] = useState<boolean>(false);

  useEffect(() => {
    if (!contract || !provider) return;

    dispatch(getSales({ contract, provider, account }));
  }, [dispatch, contract, provider, account]);

  return (
    <Container loading={loading ? loading.toString() : undefined}>
      <Pages>
        <BurgerMenu isOpened={isOpened} />
        <Header isOpened={isOpened} setIsOpened={setIsOpened} />
        <div className="App">
          <GlobalStyle />
          <Routes>
            <Route path={APP_ROUTES.HOME} element={<Home />} />
            <Route path={APP_ROUTES.SALE} element={<Sale />} />
          </Routes>
        </div>
      </Pages>
      <Footer loading={loading} />
    </Container>
  );
}
