import styled from "styled-components";
import { ReactComponent as ArrowUp } from "src/shared/assets/icons/arrow-up.svg";
import { ReactComponent as WalletLogo } from "src/shared/assets/icons/wallet.svg";

export const ArrowBtnIcon = styled(ArrowUp)`
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.75rem;
`;

export const Button = styled.button`
  width: 15rem;
  height: 3rem;
  border: unset;
  display: flex;
  font-size: 1rem;
  cursor: pointer;
  font-weight: 600;
  align-items: center;
  justify-content: center;
  border-radius: 0.331rem;
  font-family: "Inter", sans-serif;
  color: ${({ theme }) => theme.colors.globalWhite};
  background: ${({ theme }) => theme.colors.globalGreen};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    margin-top: 1.5rem;
 `}

  &:disabled {
    background: ${({ theme }) => theme.colors.globalGrey};
  }
`;

export const WalletIcon = styled(WalletLogo)`
  width: 1.313rem;
  height: 1.125rem;
  margin-right: 0.813rem;
`;
