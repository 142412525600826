import { ModalWrapperProps } from "./interface";
import { Modal, ModalWrapperStyled } from "./styles";

export default function ModalWrapper({
  children,
  closeModal,
}: ModalWrapperProps) {
  return (
    <>
      <ModalWrapperStyled onClick={closeModal} />
      <Modal>{children}</Modal>
    </>
  );
}
