export const networkConfig = {
  testnet: {
    chainId: `0x${Number(1313161555).toString(16)}`,
    chainName: "Aurora Testnet",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ["https://testnet.aurora.dev/"],
    blockExplorerUrls: ["https://testnet.aurorascan.dev/"],
  },
  mainnet: {
    chainId: `0x${Number(1313161554).toString(16)}`,
    chainName: "Aurora Mainnet",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ["https://mainnet.aurora.dev"],
    blockExplorerUrls: ["https://aurorascan.dev/"],
  },
};
