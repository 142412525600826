import { Description } from "./styles";

export default function DescriptionParagraph() {
  return (
    <Description>
      <div />
      <div />
      <div />
    </Description>
  );
}
