import styled from "styled-components";

export const BlockchainDomainName = styled.span`
  font-weight: 600;
  font-size: 0.75rem;
`;

export const DomainsWrapper = styled.div`
  display: flex;
  ${({ theme }) => theme.mediaWidth.upToSmall`margin-top: 1.25rem;`}
`;

export const BlockchainDomainButton = styled.a<{ loading?: string }>`
  display: flex;
  font-weight: 600;
  padding: 0.563rem;
  align-items: center;
  border-radius: 1rem;
  text-decoration: none;
  margin-right: 0.75rem;
  font-family: "Inter", sans-serif;
  color: ${({ theme, loading }) =>
    loading ? theme.colors.transparent : theme.colors.globalGrey};
  background: ${({ theme }) => theme.colors.opacityGrey};

  & > svg {
    width: 0.75rem;
    height: 0.75rem;
    margin-right: 0.5rem;

    path {
      fill: ${({ theme, loading }) =>
        loading ? theme.transparent : theme.colors.globalGrey};
    }
  }

  &:hover {
    color: ${({ theme, loading }) =>
      loading ? theme.transparent : theme.colors.globalBlack};
    background: ${({ theme }) => theme.colors.globalWhite};

    svg {
      path {
        fill: ${({ theme, loading }) =>
          loading ? theme.colors.transparent : theme.colors.globalGrey};
      }
    }
  }

  &:last-child {
    margin-right: 0;
  }
`;

export const BlockchainDomainTumblerDeimos = styled.span<{ loading?: string }>`
  display: flex;
  font-weight: 600;
  padding: 0.563rem;
  align-items: center;
  border-radius: 1rem;
  text-decoration: none;
  margin-right: 0.75rem;
  font-family: "Inter", sans-serif;
  color: ${({ theme, loading }) =>
    loading ? theme.colors.transparent : theme.colors.globalGrey};
  background: ${({ theme }) => theme.colors.opacityGrey};

  & > svg {
    width: 0.75rem;
    height: 0.75rem;
    margin-right: 0.5rem;

    path {
      fill: ${({ theme, loading }) =>
        loading ? theme.transparent : theme.colors.globalGrey};
    }
  }

  &:hover {
    color: ${({ theme, loading }) =>
      loading ? theme.transparent : theme.colors.globalBlack};
    background: ${({ theme }) => theme.colors.globalWhite};

    svg {
      path {
        fill: ${({ theme, loading }) =>
          loading ? theme.colors.transparent : theme.colors.globalGrey};
      }
    }
  }

  &:last-child {
    margin-right: 0;
  }

  &.current {
    color: ${({ theme, loading }) =>
      loading ? theme.colors.transparent : theme.colors.globalBlack};
    background: ${({ theme }) => theme.colors.globalWhite};

    svg {
      path {
        fill: ${({ theme, loading }) =>
          loading ? theme.colors.transparent : theme.colors.globalBlack};
      }
    }
  }
`;
