import styled from "styled-components";
import { ButtonSecondary } from "./ButtonSecondary";

export const ButtonFilter = styled(ButtonSecondary)<{ isActive?: boolean }>`
  border-radius: 0.25rem;
  color: ${({ theme, isActive }) =>
    isActive ? theme.colors.globalWhite : theme.colors.globalGreen};
  background: ${({ theme, isActive }) =>
    isActive ? theme.colors.globalGreen : theme.colors.greenBgBtn};
`;
