import { Statuses } from "src/shared/common/enums/Statuses";
import { AmountValidateArgs } from "src/shared/common/interfaces/AmountValidateArgs";
import { AmountInputError } from "../Errors/interface";
import { BigNumber } from "ethers";
import { weiToDecimal } from "src/shared/common/helpers/weiToDecimal";

export const inputRegex = RegExp("^\\d*(?:\\\\[.])?\\d*$");
export function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

const validNumberError: AmountInputError = {
  valid: false,
  reason: "inputErrors.validNumberError",
};

const smallerThanZeroError: AmountInputError = {
  valid: false,
  reason: "inputErrors.smallerThanZeroError",
};

const smallerThanMinValueError: AmountInputError = {
  valid: false,
  reason: "inputErrors.smallerThanMinValueError",
};

const greaterThanMaxValueError: AmountInputError = {
  valid: false,
  reason: "inputErrors.greaterThanMaxValueError",
};

export const risksError: AmountInputError = {
  valid: false,
  reason: "inputErrors.risks",
};

export const validateValue = ({
  isParticipant,
  value,
  availableBalance,
  min,
  max,
  deposited,
  decimal,
}: AmountValidateArgs) => {
  if (!availableBalance) return smallerThanMinValueError;
  if (!value) return smallerThanZeroError;
  const numericBigValue = BigNumber.from(value ?? 0).toNumber();
  const amount = BigNumber.from(value ?? 0);
  const maxAllocation = BigNumber.from(max);
  const minAllocation = BigNumber.from(min);
  const participateDeposited =
    isParticipant && deposited > 0
      ? Number(weiToDecimal(BigNumber.from(deposited), decimal))
      : 0;
  if (Number.isNaN(numericBigValue)) return validNumberError;
  if (numericBigValue <= 0) return smallerThanZeroError;
  if (amount.lt(minAllocation.sub(participateDeposited ?? 0)) || amount.lte(0))
    return smallerThanMinValueError;
  if (
    amount.gt(maxAllocation.sub(participateDeposited ?? 0)) ||
    amount.gt(availableBalance ?? 0)
  )
    return greaterThanMaxValueError;

  return { valid: true, reason: null };
};

export const enforcer = (target: HTMLInputElement) => {
  let value = "";
  const nextUserInput = target.value.replace(/,/g, ".");
  if (nextUserInput[0] === "." || nextUserInput[0] === ",") {
    value = `0${nextUserInput}`;
    return;
  }
  if (nextUserInput === "" || inputRegex.test(escapeRegExp(nextUserInput))) {
    value = nextUserInput;
  }

  return value;
};

export function validateCheckbox(
  checked: boolean,
  error: AmountInputError,
  inputError: AmountInputError
) {
  if (!checked && error.valid) {
    return {
      valid: error.valid,
      reason: risksError.reason,
    };
  }
  if (!inputError.valid && checked) {
    return {
      reason: inputError.reason,
      valid: false,
    };
  }

  return { reason: null, valid: true };
}
