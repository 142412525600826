import { ReactComponent as BookIcon } from "src/shared/assets/icons/book.svg";
import { ReactComponent as LightningIcon } from "src/shared/assets/icons/lightning.svg";
import { ReactComponent as LinkIcon } from "src/shared/assets/icons/link.svg";
import { ReactComponent as ListIcon } from "src/shared/assets/icons/list.svg";
import { BurgerMenuList } from "./interfaces";

export const burgerMenuList: BurgerMenuList[] = [
  {
    title: "General.BurgerMenu.About",
    logo: LightningIcon,
    link: "",
  },
  {
    title: "General.BurgerMenu.Whitepaper",
    logo: BookIcon,
    link: "",
  },
  {
    title: "General.BurgerMenu.RisksAndRules",
    logo: ListIcon,
    link: "",
  },
];

export const launchpadsList: BurgerMenuList[] = [
  {
    title: "General.BurgerMenu.OpenMars",
    logo: LinkIcon,
    link: "https://mars.bocachica.io/",
  },
  {
    title: "General.BurgerMenu.OpenMoon",
    logo: LinkIcon,
    link: "https://moon.bocachica.io/",
  },
  {
    title: "General.BurgerMenu.OpenAuction",
    logo: LinkIcon,
    link: "https://auction.bocachica.io/",
  },
];
