import styled from "styled-components";
import { ReactComponent as SearchIcon } from "src/shared/assets/icons/search.svg";
import { ReactComponent as FilterIcon } from "src/shared/assets/icons/sliders.svg";

export const ActionWrapper = styled.div<{ loading?: string }>`
  display: flex;
  margin-top: 1rem;
  font-weight: 600;
  font-size: 0.75rem;
  justify-content: space-between;
  font-family: "Inter", sans-serif;
  color: ${({ theme }) => theme.colors.globalWhite};
  opacity: ${({ loading }) => (loading ? "0" : "1")};
`;

export const SearchImg = styled(SearchIcon)`
  cursor: pointer;
`;

export const FilterImg = styled(FilterIcon)`
  cursor: pointer;
`;
