import styled from "styled-components";
import { ReactComponent as WalletLogo } from "src/shared/assets/icons/wallet.svg";

export default WalletLogo;

export const MobileWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  ${({ theme }) => theme.mediaWidth.upToSmall`width: 100%;`}
`;

export const HeaderStyled = styled.header`
  display: flex;
  margin: 0 auto;
  align-items: center;
  padding: 1.5rem 2rem;
  box-sizing: border-box;
  justify-content: space-between;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    padding: 1.25rem 1rem 1.688rem;
  `}
`;

export const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
