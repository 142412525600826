import { useState, useEffect } from "react";
import { MEDIA_WIDTHS } from "src/shared/styles/theme";
import { Dimensions } from "src/shared/common/enums/Dimensions";

function getWindowDimensions() {
  const { innerWidth: width } = window;
  if (width >= MEDIA_WIDTHS.upToLarge) return Dimensions.EXTRA_LARGE;
  if (width >= MEDIA_WIDTHS.upToMedium && width < MEDIA_WIDTHS.upToLarge)
    return Dimensions.LARGE;
  if (width >= MEDIA_WIDTHS.upToSmall && width < MEDIA_WIDTHS.upToMedium)
    return Dimensions.MEDIUM;
  if (width < MEDIA_WIDTHS.upToSmall) return Dimensions.SMALL;
  return Dimensions.UNKNOWN;
}

const useWindowDimensions = () => {
  const [windowDimension, setWindowDimension] = useState<Dimensions>(
    Dimensions.LARGE
  );

  useEffect(() => {
    function handleResize() {
      const dimension = getWindowDimensions();
      if (dimension !== windowDimension) setWindowDimension(dimension);
    }

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });

  return windowDimension;
};

export default useWindowDimensions;
