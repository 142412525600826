import styled from "styled-components";

export const ButtonPrimary = styled.button<{ isSignedIn?: boolean }>`
  border: none;
  outline: none;
  display: flex;
  cursor: pointer;
  font-size: 1rem;
  max-height: 3rem;
  font-weight: 500;
  padding: 0 0.5rem;
  min-height: 2.25rem;
  flex-direction: row;
  align-items: center;
  line-height: 1.188rem;
  border-radius: 0.5rem;
  justify-content: center;
  font-family: "Inter", sans-serif;
  color: ${({ theme }) => theme.colors.globalWhite};
  background-color: ${({ theme }) => theme.colors.globalGreen};

  &:disabled {
    cursor: not-allowed;
    background: ${({ theme }) => theme.colors.globalGrey};
  }
`;
