import { format } from "date-fns";
import Timer from "../Timer/Timer";
import Translate from "../Translate/Translate";
import { DeadlineWrapper } from "./styles";
import { SaleDateProps } from "./interface";

export default function SaleDate({
  startDate,
  endDate,
  status,
}: SaleDateProps) {
  return (
    <>
      <DeadlineWrapper>
        <div>
          <span>
            <Translate value="Swap.StartDate" />
          </span>
          <p>
            {format(startDate * 1000, "MMMM dd, yyyy HH:mm")}{" "}
            <Translate value="General.Time.UTC" />
          </p>
        </div>
        <div>
          <span>
            <Translate value="Swap.FinishDate" />
          </span>
          <p>
            {format(endDate * 1000, "MMMM dd, yyyy HH:mm")}{" "}
            <Translate value="General.Time.UTC" />
          </p>
        </div>
      </DeadlineWrapper>
      <Timer status={status} startDate={startDate} endDate={endDate} />
    </>
  );
}
