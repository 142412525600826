import styled from "styled-components";

export const TimestampStyled = styled.p`
  display: flex;
  line-height: 1;
  font-size: 1rem;
  font-weight: 600;
  align-items: center;
  font-family: "Inter", sans-serif;

  span {
    display: flex;
    font-size: inherit;
    align-items: center;
    font-family: inherit;
    color: ${({ theme }) => theme.colors.globalBlack};

    &.days {
      &:after {
        content: "";
        right: 0;
        height: 1.25rem;
        width: 0.0625rem;
        margin: 0 0.5rem;
        display: inline-block;
        border-radius: 0.0625rem;
        background-color: ${({ theme }) => theme.colors.primaryGrey};
      }
    }
  }
`;
