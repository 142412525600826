import styled from "styled-components";
import { PropsWithChildren } from "react";
import { Statuses } from "src/shared/common/enums/Statuses";

function setBackground(theme: any, roundStatus: any) {
  switch (roundStatus) {
    case Statuses.PENDING:
      return theme.statusColor.bgPending;
    case Statuses.ACTIVE:
      return theme.statusColor.bgActive;
    case Statuses.FINISHED:
      return theme.statusColor.bgFinished;
    default:
      return theme.statusColor.bgFinished;
  }
}

function setColor(theme: any, roundStatus: any) {
  switch (roundStatus) {
    case Statuses.PENDING:
      return theme.statusColor.pending;
    case Statuses.ACTIVE:
      return theme.statusColor.active;
    case Statuses.FINISHED:
      return theme.statusColor.finished;
    default:
      return theme.statusColor.finished;
  }
}

export const StatusLabelStyled = styled.p<PropsWithChildren<any>>`
  font-weight: 600;
  font-size: 0.75rem;
  max-height: 1.5rem;
  border-radius: 0.25rem;
  padding: 0.281rem 0.375rem;
  font-family: "Inter", sans-serif;
  color: ${({ theme, roundStatus }) => setColor(theme, roundStatus)};
  background: ${({ theme, roundStatus }) => setBackground(theme, roundStatus)};
`;
