import { BlockchainImg } from "./enums/BlockchainImg";
import { DeimosNetworkLinksEnum } from "./enums/DeimosNetworksLinksEnum";

export const TIMESTAMP = {
  ONE_DAY: 60 * 60 * 24,
  ONE_HOUR: 60 * 60,
  ONE_MINUTE: 60,
};

export const blockchainDomains: {
  value: string;
  type: BlockchainImg;
  name: string;
}[] = [
  {
    value: "https://mars.bocachica.io/",
    type: BlockchainImg.Near,
    name: "NEAR",
  },
  {
    value: DeimosNetworkLinksEnum.AURORA,
    type: BlockchainImg.Aurora,
    name: "Aurora",
  },
  {
    value: "https://moon.bocachica.io/",
    type: BlockchainImg.Solana,
    name: "Solana",
  },
  {
    value: "https://auction.bocachica.io/",
    type: BlockchainImg.Auction,
    name: "Auction",
  },
];

export const NUMBER_PLACEHOLDER_CARD = Array.from(Array(5).keys());
export const NUMBER_PLACEHOLDER_ROW = Array.from(Array(6).keys());
export const NUMBER_PLACEHOLDER_VALUE = Array.from(Array(2).keys());
