import styled from "styled-components";
import { ButtonFilter } from "../../Buttons/ButtonFilter";
import { ButtonSecondary } from "../../Buttons/ButtonSecondary";
import { ReactComponent as CloseIcon } from "src/shared/assets/icons/close-modal.svg";

export const Header = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  margin-bottom: 1.5rem;
  justify-content: center;

  & > p {
    margin: 0;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-family: "Inter", sans-serif;
    color: ${({ theme }) => theme.colors.globalBlack};
  }
`;

export const Close = styled(CloseIcon)`
  right: 0;
  fill: black;
  width: 14px;
  height: 14px;
  cursor: pointer;
  position: absolute;
`;

export const Body = styled.div`
  display: flex;
  min-width: 22.5rem;
  flex-direction: column;
  ${({ theme }) => theme.mediaWidth.upToSmall`min-width: 100%;`}
`;

export const Wrapper = styled.div`
  display: flex;
  margin-bottom: 1.5rem;
  flex-direction: column;
`;

export const TitleWrapper = styled.p`
  font-weight: 500;
  font-style: normal;
  font-size: 0.75rem;
  line-height: 0.938rem;
  margin-bottom: 0.75rem;
  font-family: "Inter", sans-serif;
  color: ${({ theme }) => theme.colors.primaryGrey};
`;

export const WrapperBtn = styled.div`
  display: flex;
  align-items: center;
`;

export const FilterBtn = styled(ButtonFilter)`
  font-weight: 500;
  font-size: 0.75rem;
  padding: 0 0.75rem;
  margin-right: 0.5rem;
  line-height: 0.938rem;
`;

export const ApplyBtn = styled(ButtonSecondary)`
  font-weight: 500;
  margin: 0 0.25rem;
  font-size: 0.75rem;
  line-height: 0.938rem;
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 1.5rem;

  & > button {
    width: 100%;
    height: 100vh;
  }
`;
