import styled from "styled-components";

export const Description = styled.div`
  margin-top: 2rem;

  div {
    width: 100%;
    height: 0.5rem;
    margin-top: 1rem;
    border-radius: 0.25rem;
    background-color: ${({ theme }) => theme.colors.opacityGrey};

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      width: 16.523rem;
    }
  }
`;
