import styled from "styled-components";
import { ReactComponent as ArrowIcon } from "src/shared/assets/icons/arrow-icon.svg";

export const RoundItem = styled.div`
  width: 100%;
  padding: 1rem;
  cursor: pointer;
  font-weight: 600;
  position: relative;
  margin-top: 1.5rem;
  border-radius: 1rem;
  font-family: "Inter", sans-serif;
  background: ${({ theme }) => theme.colors.globalWhite};
  box-shadow: 0 0.25rem 0.5rem -0.25rem ${({ theme }) => theme.shadowColor.shadowWrapper};

  &:first-child {
    margin-top: 0;
  }
`;

export const NameValue = styled.p`
  font-weight: 500;
  font-size: 0.75rem;
  font-family: "Inter", sans-serif;
  color: ${({ theme }) => theme.colors.primaryGrey};
`;

export const CurrencyValue = styled.p`
  font-size: 1rem;
  font-weight: 600;
  padding-top: 0.375rem;
  font-family: "Inter", sans-serif;
  color: ${({ theme }) => theme.colors.globalBlack};
`;

export const NavigationRow = styled.div`
  display: grid;
  align-items: end;
  margin-top: 1rem;
  grid-column-gap: 1.5rem;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr 19rem;

  div {
    &:nth-child(2) {
      text-align: right;
    }
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    grid-row-gap: 1rem;
    grid-template-rows: auto 1fr;
    grid-template-columns: repeat(2, 1fr);

    > div {
      &:last-child {
        width: 100%;
        grid-column-start: 1;
        grid-column-end: 3;
      }
    }
 `}
`;

export const RoundOpened = styled.span`
  z-index: -2;
  top: -0.1875rem;
  left: -0.1875rem;
  position: absolute;
  border-radius: 1rem;
  width: calc(100% + 0.375rem);
  height: calc(100% + 0.375rem);
  background: ${({ theme }) => theme.statusColor.active};

  &::after {
    content: "";
    top: 0.125rem;
    left: 0.125rem;
    display: block;
    position: absolute;
    border-radius: 1rem;
    width: calc(100% - 0.25rem);
    height: calc(100% - 0.25rem);
    background: ${({ theme }) => theme.statusColor.activeDarken};
  }
`;

export const Arrow = styled(ArrowIcon)`
  cursor: pointer;
`;
