import { PropsWithChildren } from "react";
import styled from "styled-components";

export const TimerStyled = styled.div<
  PropsWithChildren<{ isHome: boolean | undefined }>
>`
  display: flex;
  height: 1.75rem;
  font-weight: 400;
  padding: 0 0.5rem;
  font-size: 0.75rem;
  align-items: center;
  border-radius: 0.25rem;
  justify-content: space-between;
  font-family: "Inter", sans-serif;
  color: ${({ theme }) => theme.colors.globalBlack};
  margin-top: ${({ isHome }) => (isHome ? "0" : "3rem")};
  background: rgba(${({ theme }) => theme.colors.bgGrey}, 0.2);
`;
