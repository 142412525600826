import { Statuses } from "../enums/Statuses";

export function detectTimestamp(
  startDate: number,
  endDate: number,
  status: string
) {
  if (status === Statuses.PENDING) {
    return startDate;
  }

  return endDate;
}
