import { BigNumber } from "ethers";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Vesting from "src/shared/components/Vesting/Vesting";
import SaleDate from "src/shared/components/SaleDate/SaleDate";
import useWindowDimensions from "src/hooks/useWindowDimensions";
import Translate from "src/shared/components/Translate/Translate";
import ValueSale from "src/shared/components/ValueSale/ValueSale";
import ErrorWarning from "src/shared/components/Errors/ErrorWarning";
import SocialNetwork from "src/shared/components/SocialNetwork/SocialNetwork";
import AmountProgress from "src/shared/components/AmountProgress/AmountProgress";
import SaleLayoutInfo from "src/shared/components/SaleLayoutInfo/SaleLayoutInfo";
import BigSkeleton from "src/shared/components/Placeholder/BigSkeleton/BigSkeleton";
import AmountInputContainer from "src/shared/components/AmountInputContainer/AmountInputContainer";
import HandleSaleActionButton from "src/shared/components/HandleSaleActionButton/HandleSaleActionButton";
import { Dimensions } from "src/shared/common/enums/Dimensions";
import { AmountInputError } from "src/shared/components/Errors/interface";
import { ParticipantModel } from "src/shared/common/interfaces/ParticipantModel";
import { validateCheckbox } from "src/shared/components/AmountInputContainer/helpers";
import {
  AcceptInput,
  ActionContainer,
  Approve,
  ApproveDescription,
  Container,
  Description,
  ExternalLink,
  InfoWrapper,
  SocialBox,
  SocialWrapper,
  Wrapper,
} from "./styles";
import { Social } from "src/shared/common/enums/Social";
import { deposit, getSale } from "src/store/slices/sales/helpers";
import {
  clearSaleAccountData,
  getSaleAccountData,
  updateSaleInList,
} from "src/store/slices/sales/sales";
import { useAppSelector } from "src/hooks/redux/useAppSelector";
import { useAppDispatch } from "src/hooks/redux/useAppDispatch";
import { SaleDataById } from "src/shared/common/interfaces/Sale";
import { getSales, getSalesLoading } from "src/store/slices/sales/selectors";
import { useWalletServiceContext } from "src/contexts/WalletProvider/WalletProvider";

export default function Sale() {
  const dimension = useWindowDimensions();
  const dispatch = useAppDispatch();
  const { id } = useParams();

  const sales = useAppSelector(getSales);
  const loading = useAppSelector(getSalesLoading);

  const { contract, provider, account, signer, environment } =
    useWalletServiceContext();

  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [amount, setAmount] = useState<string>("");
  const [claimValue, setClaimValue] = useState<string | number>("0");
  const [purchase, setPurchase] = useState<string | number>("0");
  const [participantData, setParticipantData] =
    useState<ParticipantModel | null>(null);
  const [isParticipant, setIsParticipant] = useState<boolean>(false);
  const [refund, setRefund] = useState<number | string>("0");
  const [error, setError] = useState<AmountInputError>({
    valid: true,
    reason: null,
  });
  const [inputError, setInputError] = useState<AmountInputError>({
    valid: true,
    reason: null,
  });

  const [sale, setSale] = useState<SaleDataById | null>(null);

  useEffect(() => {
    if (!id || !sale || !provider || !account) return;

    dispatch(
      getSaleAccountData({
        saleId: Number(id),
        depositTokenAddress: sale.depositToken.address,
        provider,
        account,
      })
    );

    return () => {
      dispatch(clearSaleAccountData());
    };
  }, [id, sale, provider, account]);

  useEffect(() => {
    if (!id) return;
    const currentSale = sales.find(
      (sale: SaleDataById) => sale.saleId === Number(id)
    );

    currentSale && setSale(currentSale);
  }, [id, sales]);

  const socials = sale && [
    { value: sale.project.medium, type: Social.Medium },
    { value: sale.project.telegram, type: Social.Telegram },
    { value: sale.project.twitter, type: Social.Twitter },
  ];

  function checkRisks({ target }: { target: HTMLInputElement }) {
    const { checked } = target;
    const validate = validateCheckbox(checked, error, inputError);
    setIsChecked(checked);
    setError(validate);
  }

  async function callDeposit() {
    if (
      !sale ||
      !id ||
      !provider ||
      !contract ||
      !signer ||
      !environment ||
      !account
    )
      return;

    try {
      const validateTerms = validateCheckbox(isChecked, error, inputError);
      setError(validateTerms);

      if (!validateTerms.valid || !isChecked) return;

      const depositResult = await deposit(
        sale.depositToken.address,
        Number(amount),
        sale.depositToken.decimals,
        Number(id),
        contract,
        signer,
        environment,
        account
      );

      if (depositResult) {
        await depositResult.wait();
        const updatedSale = await getSale(
          Number(id),
          provider,
          contract,
          account
        );

        if (updatedSale) {
          const updatedSaleIndex = sales.findIndex(
            (sale: SaleDataById) => sale.saleId === updatedSale.saleId
          );

          dispatch(updateSaleInList({ updatedSale, updatedSaleIndex }));
        }
      }
    } catch (err) {
      console.error(err);
    }
  }

  if (loading || !sale) return <BigSkeleton />;

  return (
    <Container>
      <Wrapper>
        <SaleLayoutInfo sale={sale} isSale />
        <ActionContainer>
          <AmountInputContainer
            participantData={participantData}
            isParticipant={isParticipant}
            currentSale={sale}
            setAmount={setAmount}
            amount={amount}
            purchase={purchase}
            claimValue={BigNumber.from(claimValue)}
            refundValue={BigNumber.from(refund)}
            error={error}
            setError={setError}
            setInputError={setInputError}
          />
          <HandleSaleActionButton
            currentSale={sale}
            deposit={callDeposit}
            error={error}
            value={amount}
          />
        </ActionContainer>
        {error.reason && <ErrorWarning reason={error.reason} />}
        <Approve>
          <AcceptInput
            type="checkbox"
            checked={isChecked}
            onChange={checkRisks}
          />
          <ApproveDescription>
            <Translate value="Swap.Approve" />
          </ApproveDescription>
        </Approve>

        <InfoWrapper>
          <ValueSale
            participantData={participantData}
            currentSale={sale}
            purchase={purchase}
          />
          <AmountProgress
            targetDeposit={sale.targetDeposit}
            currentDeposit={sale.currentDeposit}
            depositToken={sale.depositToken}
          />
          <SaleDate
            startDate={sale.startDate}
            endDate={sale.endDate}
            status={sale.status}
          />
          <Description>{sale.project.description}</Description>
          <SocialWrapper>
            {socials && <SocialNetwork socials={socials} isSwap />}
            <SocialBox href="#">
              <p>
                {dimension !== Dimensions.SMALL
                  ? sale.project.name
                  : `Go to ${sale.project.name}`}
              </p>
              <ExternalLink />
            </SocialBox>
          </SocialWrapper>
        </InfoWrapper>
      </Wrapper>
    </Container>
  );
}
