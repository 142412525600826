import { useState } from "react";
import { v4 as uuid } from "uuid";
import { toArray } from "src/shared/common/helpers/toArray";
import { Filter } from "src/shared/common/interfaces/Filter";
import { FilterByResult } from "src/shared/common/enums/FilterByResult";
import { FilterByStatus } from "src/shared/common/enums/FilterByStatus";
import { FilterByParticipation } from "src/shared/common/enums/FilterByParticipation";
import ModalWrapper from "../ModalWrapper";
import Translate from "../../Translate/Translate";
import { ButtonPrimary } from "../../Buttons/ButtonPrimary";
import { FilterModalProps } from "./interface";
import {
  Body,
  Close,
  FilterBtn,
  Footer,
  Header,
  TitleWrapper,
  Wrapper,
  WrapperBtn,
} from "./styles";
import { useAppSelector } from "src/hooks/redux/useAppSelector";
import { getSaleFilterType } from "src/store/slices/sales/selectors";

export default function FilterModal({ closeModal }: FilterModalProps) {
  const filterType = useAppSelector(getSaleFilterType);
  const [filter, setFilter] = useState<Filter>(filterType);

  const handleConfirm = () => {
    // TODO: https://hapilabs.atlassian.net/jira/software/projects/BCM/boards/8?selectedIssue=BCM-178
    closeModal();
  };

  return (
    <ModalWrapper closeModal={closeModal}>
      <Header>
        <p>
          <Translate value="General.Modal.Filters" />
        </p>
        <Close onClick={closeModal} />
      </Header>

      <Body>
        <Wrapper>
          <TitleWrapper>
            <Translate value="General.Filter.Status" />
          </TitleWrapper>
          <WrapperBtn>
            {toArray(FilterByStatus).map((status) => (
              <FilterBtn
                key={`${uuid()}-${status}`}
                isActive={status === filter.filterByStatus}
                onClick={() =>
                  setFilter({
                    ...filter,
                    filterByStatus: status,
                  })
                }
              >
                {status}
              </FilterBtn>
            ))}
          </WrapperBtn>
        </Wrapper>

        <Wrapper>
          <TitleWrapper>
            <Translate value="General.Filter.PoolParticipation" />
          </TitleWrapper>
          <WrapperBtn>
            {toArray(FilterByParticipation).map((status) => (
              <FilterBtn
                key={`${uuid()}-${status}`}
                isActive={status === filter.filterByParticipation}
                onClick={() =>
                  setFilter({
                    ...filter,
                    filterByParticipation: status,
                  })
                }
              >
                {status}
              </FilterBtn>
            ))}
          </WrapperBtn>
        </Wrapper>

        <Wrapper>
          <TitleWrapper>
            <Translate value="General.Filter.Result" />
          </TitleWrapper>
          <WrapperBtn>
            {toArray(FilterByResult).map((status) => (
              <FilterBtn
                key={`${uuid()}-${status}`}
                isActive={status === filter.filterByResult}
                onClick={() =>
                  setFilter({
                    ...filter,
                    filterByResult: status,
                  })
                }
              >
                {status}
              </FilterBtn>
            ))}
          </WrapperBtn>
        </Wrapper>
      </Body>

      <Footer>
        <ButtonPrimary onClick={() => handleConfirm()}>
          <Translate value="General.Action.Apply" />
        </ButtonPrimary>
      </Footer>
    </ModalWrapper>
  );
}
